import { useSWRInfinite } from "swr";
import { API } from "universal/types/api";
import { Maybe, Nullable } from "universal/types/utils";

const PAGE_SIZE = 10;

type Data = {
  listCount: number;
  lists: API.List[];
};

export function useLists(userId: Maybe<string>) {
  const { data, size, setSize, error, mutate } = useSWRInfinite(getKey);

  const lists = flatten(data);
  const paginate = enablePagination(size, data)
    ? async () => {
        await setSize(size + 1);
      }
    : null;

  return {
    loading: !data && !error,
    error,
    lists,
    paginate,
    mutate,
    mutatePrepend,
    mutateUpdate,
    mutateRemove,
  };

  function getKey(pageIndex: number, previousPageData: Data) {
    const reachedEnd = previousPageData && previousPageData.lists.length === 0;

    if (!userId || reachedEnd) return null;

    return `/api/lists?userId=${userId}&offset=${
      pageIndex * PAGE_SIZE
    }&limit=${PAGE_SIZE}`;
  }

  async function mutatePrepend(list: API.List) {
    return await mutate((data) => {
      return [{ listCount: 0, lists: [list] }, ...(data || [])];
    }, false);
  }

  async function mutateUpdate(list: API.List) {
    return await mutate((data) => {
      return data?.map((data) => {
        return {
          ...data,
          lists: data.lists.map((candidate) => {
            return candidate.id === list.id ? list : candidate;
          }),
        };
      });
    }, false);
  }

  async function mutateRemove(list: API.List) {
    return await mutate((data) => {
      return data?.map((data) => {
        return {
          ...data,
          lists: data.lists.filter((candidate) => {
            return candidate.id !== list.id;
          }),
        };
      });
    }, false);
  }
}

function enablePagination(size: number, data: Maybe<Data[]>) {
  if (!data) return false;

  const latest = data[data.length - 1];

  return size * PAGE_SIZE < latest.listCount;
}

function flatten(data: Maybe<Data[]>): Nullable<API.List[]> {
  if (!data) return null;

  return data.flatMap((x) => x.lists);
}
