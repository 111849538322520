import { Film, Viewmaster } from "client/components/svgs";
import { Link } from "client/components/Link";
import styles from "./BrandLogoLink.module.css";

interface Props {
  href: string;
}

export function BrandLogoLink(props: Props) {
  const { href } = props;

  return (
    <Link className={`flex items-end h-10 ${styles.link}`} href={href}>
      <div className="relative w-10">
        <Film className={`${styles.film}`} />
        <Viewmaster className={`relative w-full ${styles.viewmaster}`} />
      </div>
      <div className="ml-2 text-2xl font-bold">Vicariously</div>
    </Link>
  );
}
