import { Container } from "client/components/Container";
import { Footer } from "client/components/Footer";
import { Header } from "client/components/Header";

interface Props {
  children: React.ReactNode;
}

export function MarketingShell(props: Props) {
  const { children } = props;

  return (
    <div className="min-h-screen h-full bg-cultured flex flex-col">
      <div className="flex-none">
        <Header />
      </div>

      <div className="flex-1">
        <Container>
          <div className="py-12">{children}</div>
        </Container>
      </div>

      <div className="flex-none">
        <Footer />
      </div>
    </div>
  );
}
