import { Modal } from "client/components/Modal";
import styles from "./Dialog.module.css";

interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

export function Dialog(props: Props) {
  const { onClose, children } = props;

  return (
    <Modal
      isOpen={true}
      className={`w-full p-2 focus:outline-none ${styles.outer}`}
      overlayClassName="fixed inset-0 bg-cultured bg-opacity-[.95]"
      onRequestClose={onClose}
    >
      <div
        className={`p-6 rounded-t-lg bg-cultured ring-8 ring-davys overflow-y-scroll sm:rounded-lg ${styles.inner}`}
      >
        {children}
      </div>
    </Modal>
  );
}
