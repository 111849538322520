import { ListType } from "@prisma/client";
import { AlertBox } from "client/components/AlertBox";
import { Button } from "client/components/Button";
import { Dialog } from "client/components/Dialog";
import { Form, HiddenField, SubmitButton } from "client/components/form";
import {
  Classic,
  Difference,
  Intersect,
  Subtract,
  Union,
} from "client/components/svgs";
import React from "react";
import { API } from "universal/types/api";
import { Awaitable } from "universal/types/utils";
import { PreviewSubject } from "./PreviewSubject";

type Props = {
  preview: API.ListPreview;
  onCreate: (listType: ListType, screenName: string) => Awaitable<void>;
  onCancel: () => void;
};

type FormValues = {
  listType: ListType;
  canonical: string;
};

export function PreviewListDialog(props: Props) {
  const { preview, onCancel, onCreate } = props;

  const initialValues = {
    listType: preview.listType,
    canonical: preview.subjects
      .filter((subject) => !subject.denylist)
      .map((subject) => subject.canonical)
      .join(", "),
  };

  return (
    <Dialog onClose={onCancel}>
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        <HiddenField name="listType" />
        <HiddenField name="canonical" />

        <div className="text-2xl font-bold">List preview</div>

        <div className="mt-4">{renderListType()}</div>

        <div className="flex mt-4 px-2 bg-davys text-cultured justify-between items-center">
          <div className="font-bold">Subject</div>
          <div className="font-bold">Count</div>
        </div>

        <ol className="my-4">
          {preview.subjects.map((subject, i) => {
            const classes = i > 0 ? "mt-4" : "";

            return (
              <li key={subject.canonical} className={classes}>
                <PreviewSubject subject={subject} />
              </li>
            );
          })}
        </ol>

        <div className="flex px-2 bg-davys text-cultured font-bold justify-between items-center">
          <div>Estimated list size:</div>
          <div className={preview.exceedsCapacity ? "text-warning" : ""}>
            {preview.estimatedListSize.toLocaleString()}
          </div>
        </div>

        {renderWarningsMaybe()}

        <div className="mt-16 flex justify-end">
          <Button
            color="cultured"
            border={true}
            size="medium"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <div className="ml-4">
            <SubmitButton
              color="davys"
              border={false}
              size="medium"
              disabled={!preview.valid}
              allowPristineSubmit={true}
            >
              Create
            </SubmitButton>
          </div>
        </div>
      </Form>
    </Dialog>
  );

  function renderWarningsMaybe() {
    if (
      preview.errors.length === 0 &&
      preview.valid &&
      !preview.exceedsCapacity &&
      !preview.includesDenylistUsers
    ) {
      return null;
    }

    return (
      <ul className="mt-4">
        {preview.includesDenylistUsers && (
          <li className="mt-4">
            <AlertBox type="warning" size="sm">
              You selected some Twitter users who have chosen to prevent others
              from creating lists based on their follows. The follows of these
              users will not be used when creating your list.
            </AlertBox>
          </li>
        )}
        {preview.exceedsCapacity && (
          <li className="mt-4">
            <AlertBox type="warning" size="sm">
              Twitter lists can only contain 5,000 members and this list could
              potentially exceed that cap. You can still create the list,
              however it may not contain every user it should.
            </AlertBox>
          </li>
        )}
        {preview.errors.length > 0 && (
          <li className="mt-4">
            <AlertBox type="warning" size="sm">
              The following{" "}
              {preview.errors.length > 1 ? "values do" : "value does"} not
              resolve to a valid Twitter list or Twitter user:{" "}
              {preview.errors.map((x, i) => {
                const prefix = i === 0 ? "" : ", ";
                return (
                  <React.Fragment key={x}>
                    {prefix}
                    <span className="font-bold">{x}</span>
                  </React.Fragment>
                );
              })}
            </AlertBox>
          </li>
        )}
        {!preview.valid && (
          <li className="mt-4">
            <AlertBox type="error" size="sm">
              You must select at least 1 valid user to create a list. Select a
              different set of users and try again.
            </AlertBox>
          </li>
        )}
      </ul>
    );
  }

  function renderListType() {
    const className = "flex-none h-5";

    switch (preview.listType) {
      case "CLASSIC":
        return (
          <div className="flex items-center">
            <Classic className={className} background="cultured" />
            <div className="ml-1 font-bold">Classic</div>
          </div>
        );

      case "UNION":
        return (
          <div className="flex items-center">
            <Union className={className} background="cultured" />
            <div className="ml-1 font-bold">Union</div>
          </div>
        );

      case "INTERSECT":
        return (
          <div className="flex items-center">
            <Intersect className={className} background="cultured" />
            <div className="ml-1 font-bold">Intersect</div>
          </div>
        );

      case "SUBTRACT":
        return (
          <div className="flex items-center">
            <Subtract className={className} background="cultured" />
            <div className="ml-1 font-bold">Subtract</div>
          </div>
        );

      case "DIFFERENCE":
        return (
          <div className="flex items-center">
            <Difference className={className} background="cultured" />
            <div className="ml-1 font-bold">Difference</div>
          </div>
        );
    }
  }

  async function onSubmit(values: FormValues) {
    await onCreate(values.listType, values.canonical);
  }
}
