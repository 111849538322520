import { CheckboxField, Form, SubmitButton } from "client/components/form";
import { linkProps as faqLinkProps } from "pages/faq";
import { Link } from "client/components/Link";
import React from "react";
import { API } from "universal/types/api";
import { Awaitable } from "universal/types/utils";
import { SectionCard } from "./SectionCard";
import { FollowPrompt } from "./FollowPrompt";

interface Props {
  user: API.UserSelf;
  onSubmit: (values: API.NotificationSettings) => Awaitable<void>;
}

type FormValues = {
  listAbandoned: boolean;
};

export function NotificationSettingsCard(props: Props) {
  const { user, onSubmit } = props;

  const initialValues = {
    listAbandoned: !!user.notificationSettings.listAbandoned,
  };

  return (
    <SectionCard title="Notification Settings">
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        <div className="mt-8">
          <CheckboxField
            name="listAbandoned"
            border={false}
            label={
              <React.Fragment>
                Send direct message when a list has been{" "}
                <Link className="link" href={faqLinkProps("abandoned").href}>
                  abandoned
                </Link>
              </React.Fragment>
            }
          />
        </div>

        <div className="mt-8">
          <SubmitButton color="cultured" border={false} size="medium">
            Update notification settings
          </SubmitButton>
        </div>
      </Form>

      <div className="mt-8">
        <FollowPrompt user={user} />
      </div>
    </SectionCard>
  );
}
