import { ListType } from "@prisma/client";
import { Form, SubmitButton, TextField } from "client/components/form";
import React from "react";

type Props = {
  listType: ListType;
  initialSubjects: string;
  onSubmit: (listType: string, screenName: string) => Promise<void>;
};

type FormValues = {
  subjects: string;
};

export function NewListForm(props: Props) {
  const { listType, initialSubjects, onSubmit } = props;

  const initialValues = {
    subjects: initialSubjects,
  };

  return (
    <Form initialValues={initialValues} onSubmit={onSubmitProxy}>
      <div className="mt-2">{renderInstructions(listType)}</div>

      <div className="mt-8">
        <div className="sm:flex">
          <div className="sm:flex-1">
            <div>
              <TextField
                name="subjects"
                fullWidth={true}
                placeholder={getPlaceholder(listType)}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                required={true}
              />
            </div>
          </div>

          <div className="mt-6 sm:mt-0 sm:flex-none sm:w-1/4 sm:ml-4">
            <SubmitButton
              color="cultured"
              border={false}
              size="medium"
              fullWidth={true}
              allowPristineSubmit={true}
            >
              Create…
            </SubmitButton>
          </div>
        </div>
      </div>
    </Form>
  );

  function getPlaceholder(listType: string) {
    return listType === "CLASSIC"
      ? "@getvicarious"
      : "@getvicarious, @JakeHarding";
  }

  function renderInstructions(listType: string) {
    switch (listType) {
      case "CLASSIC":
        return (
          <span>
            <span className="text-pink font-bold">Classic</span> lists contain
            all the follows of a particular Twitter user.
          </span>
        );

      case "UNION":
        return (
          <span>
            <span className="text-pink font-bold">Union</span> lists contain all
            the follows of a set of Twitter users.
          </span>
        );

      case "INTERSECT":
        return (
          <span>
            <span className="text-pink font-bold">Intersect</span> lists contain
            the mutual follows of a set of Twitter users.
          </span>
        );

      case "SUBTRACT":
        return (
          <span>
            <span className="text-pink font-bold">Subtract</span> lists contain
            the follows of a particular Twitter user that are not also followed
            by a set other users.
          </span>
        );

      case "DIFFERENCE":
        return (
          <span>
            <span className="text-pink font-bold">Difference</span> lists
            contain the exclusive follows of a set of Twitter users.
          </span>
        );
    }
  }

  async function onSubmitProxy(values: FormValues) {
    await onSubmit(listType, values.subjects);
  }
}
