import { API } from "universal/types/api";

type Data = {
  list: API.List;
};

export async function createList(
  listType: string,
  screenName: string
): Promise<Data> {
  const res = await fetch("/api/lists", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ listType, screenName }),
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.error);
  }

  return json;
}
