import { Card } from "client/components/Card";
import { TwitterLine } from "client/components/icons";
import { LogInButton } from "client/components/LogInButton";
import { MarketingShell } from "client/components/MarketingShell";
import React from "react";
import { Maybe } from "universal/types/utils";

type Props = {
  redirectTo: Maybe<string>;
};

export function LogInPage(props: Props) {
  const { redirectTo } = props;

  return (
    <MarketingShell>
      <h2 className="text-xl text-center font-bold leading-tight sm:text-2xl md:text-4xl">
        Vicariously uses Twitter for log in
      </h2>

      <div className="mt-8 md:mt-16">
        <Card color="davys" padding="6">
          <div className="text-center font-bold sm:text-xl">
            Clicking the log in button will take you to Twitter to authorize
            Vicariously
          </div>

          <div className="mt-8 flex justify-center">
            <LogInButton
              size="medium"
              border={false}
              color="cultured"
              redirectTo={redirectTo}
            >
              <div className="flex items-center">
                <TwitterLine className="w-6 h-6 fill-current" />
                <div className="ml-2">Log in</div>
              </div>
            </LogInButton>
          </div>
        </Card>
      </div>
    </MarketingShell>
  );
}
