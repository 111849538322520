import { loadStripe } from "@stripe/stripe-js";
import { useToasts } from "client/components/Toast";
import { config } from "config/public";
import { ButtonBase } from "client/components/Button";
import { captureException } from "universal/lib/monitoring";

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

interface Props {
  background: "davys" | "cultured";
  context: Context;
}

type Context = "general" | "moreLists" | "premiumList";

export function SubscriptionUpgrade(props: Props) {
  const { background, context } = props;

  const { addToast } = useToasts();

  const { textColorClass, choiceTextColorClass, choiceBackgroundClass } =
    background === "davys"
      ? {
          textColorClass: "text-cultured",
          choiceTextColorClass: "text-davys",
          choiceBackgroundClass: "bg-cultured",
        }
      : {
          textColorClass: "text-davys",
          choiceTextColorClass: "text-cultured",
          choiceBackgroundClass: "bg-davys",
        };

  return (
    <div className={textColorClass}>
      {renderCopy(context)}

      <div className="mt-6 px-2 flex sm:mt-12">
        <ButtonBase
          className={`flex-1 py-6 rounded-md flex flex-col items-center outline-none ring-pink sm:py-12 hover:ring-8 focus-visible:ring-8 active:relative active:top-[1px] ${choiceBackgroundClass} ${choiceTextColorClass}`}
          onClick={() => redirectToCheckout("monthly")}
        >
          <div className="text-2xl font-bold leading-none sm:text-6xl">$5</div>
          <div className="sm:text-xl">per month</div>
        </ButtonBase>

        <ButtonBase
          className={`flex-1 ml-4 py-6 rounded-md flex flex-col items-center outline-none ring-pink sm:ml-8 sm:py-12 hover:ring-8 focus-visible:ring-8 active:relative active:top-[1px] ${choiceBackgroundClass} ${choiceTextColorClass}`}
          onClick={() => redirectToCheckout("yearly")}
        >
          <div className="text-2xl font-bold leading-none sm:text-6xl">$12</div>
          <div className="sm:text-xl">per year</div>
        </ButtonBase>
      </div>

      <div className="mt-4 text-center">You can cancel any time.</div>
    </div>
  );

  function renderCopy(context: Context) {
    const { headline, lede } = getCopyParts(context);

    return (
      <div>
        <div className="text-lg font-bold sm:text-2xl">{headline}</div>

        <div className="mt-1">
          {lede} Upgrade to get{" "}
          <span className="text-pink font-bold">unlimited lists</span>, access
          to <span className="text-pink font-bold">premium list types</span>,
          and{" "}
          <span className="text-pink font-bold">custom sync frequencies</span>.
        </div>
      </div>
    );
  }

  function getCopyParts(context: Context) {
    switch (context) {
      case "general":
        return {
          headline: "",
          lede: "Get more out of Vicariously with a subscription.",
        };

      case "moreLists":
        return {
          headline: "Want to create more lists?",
          lede:
            "Good news, the first list is on Vicariously. Bad news, subsequent ones are not.",
        };
      case "premiumList":
        return {
          headline: "Interested in premium list types?",
          lede:
            "An active subscription is required to create this type of list.",
        };
    }
  }

  async function redirectToCheckout(subscriptionType: string) {
    const res = await fetch("/api/stripe/checkout-session", {
      method: "post",

      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ subscriptionType }),
    });

    const json = await res.json();

    if (!res.ok) {
      addToast({ type: "error", body: json.error });
      return;
    }

    const stripe = await stripePromise;
    const { error } = await stripe!.redirectToCheckout({
      sessionId: json.id,
    });

    if (error) {
      captureException(error);
      addToast({ type: "error", body: error.message });
    }
  }
}
