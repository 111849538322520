import { SyncFrequency } from "@prisma/client";
import { Button } from "client/components/Button";
import { Dialog } from "client/components/Dialog";
import { Form, RadioField, SubmitButton } from "client/components/form";
import { API } from "universal/types/api";
import { Awaitable } from "universal/types/utils";

interface Props {
  list: API.List;
  onCancel: () => void;
  onChange: (syncFrequency: SyncFrequency) => Awaitable<void>;
}

type FormValues = {
  syncFrequency: SyncFrequency;
};

export function ChangeSyncFrequencyDialog(props: Props) {
  const { list, onCancel, onChange } = props;

  const initialValues = {
    syncFrequency: list.syncFrequency,
  };

  return (
    <Dialog onClose={onCancel}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        <div className="text-2xl font-bold">Change sync frequency</div>

        <div className="mt-4">
          <RadioField
            name="syncFrequency"
            border={true}
            label="Never"
            value="NEVER"
          />
        </div>

        <div className="mt-4">
          <RadioField
            name="syncFrequency"
            border={true}
            label="Daily"
            value="DAILY"
          />
        </div>

        <div className="mt-4">
          <RadioField
            name="syncFrequency"
            border={true}
            label="Weekly"
            value="WEEKLY"
          />
        </div>

        <div className="mt-4">
          <RadioField
            name="syncFrequency"
            border={true}
            label="Monthly"
            value="MONTHLY"
          />
        </div>

        <div className="mt-16 flex justify-end">
          <Button
            color="cultured"
            border={true}
            size="medium"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <div className="ml-4">
            <SubmitButton color="davys" border={false} size="medium">
              Change
            </SubmitButton>
          </div>
        </div>
      </Form>
    </Dialog>
  );

  async function onSubmit(values: FormValues) {
    await onChange(values.syncFrequency);
  }
}
