import { ExternalLink } from "client/components/Link";
import { MarketingShell } from "client/components/MarketingShell";

type Props = {};

export function ErrorPage(props: Props) {
  return (
    <MarketingShell>
      <h1 className="mb-8 text-2xl font-bold">That shouldn't happen</h1>

      <div className="mt-8">
        Sorry about that. If the problem persists or you'd like some support,
        please tweet or direct message{" "}
        <ExternalLink className="link" href="https://twitter.com/getvicarious">
          @getvicarious
        </ExternalLink>
        .
      </div>
    </MarketingShell>
  );
}
