import { ListType } from "@prisma/client";
import { Card } from "client/components/Card";
import { SubscriptionUpgrade } from "client/components/SubscriptionUpgrade";
import {
  Classic,
  Difference,
  Intersect,
  Subtract,
  Union,
} from "client/components/svgs";
import { useToasts } from "client/components/Toast";
import { previewList } from "client/lib/mutations";
import { API } from "universal/types/api";
import React, { useState } from "react";
import { Awaitable, Nullable } from "universal/types/utils";
import { ListOption } from "./ListOption";
import styles from "./NewListCta.module.css";
import { NewListForm } from "./NewListForm";
import { PreviewListDialog } from "./PreviewListDialog";

type Props = {
  initialListType: Nullable<ListType>;
  initialSubjects: string;
  allowCreation: boolean;
  allowPremiumLists: boolean;
  onCreate: (listType: string, canonical: string) => Awaitable<void>;
};

const PREMIUM_LIST_TYPES: ListType[] = [
  "UNION",
  "INTERSECT",
  "SUBTRACT",
  "DIFFERENCE",
];

export function NewListCta(props: Props) {
  const {
    initialListType,
    initialSubjects,
    allowCreation,
    allowPremiumLists,
    onCreate,
  } = props;

  const { addToast } = useToasts();

  const [listType, setListType] = useState<ListType | null>(initialListType);
  const [preview, setPreview] = useState<API.ListPreview | null>(null);

  return (
    <Card color="davys" padding="0">
      <div className="px-6 pt-6 text-xl font-bold leading-none sm:text-2xl md:text-4xl">
        Create a new list
      </div>

      <div
        className={`pl-6 py-6 flex overflow-x-auto ${styles.scrollContainer}`}
      >
        <div className="relative flex-none w-2/5 sm:flex-1">
          <ListOption
            Icon={Classic}
            name="Classic"
            selected={listType === "CLASSIC"}
            onClick={() => onListTypeSelect("CLASSIC")}
          />
        </div>

        <div className="flex-none w-2/5 ml-4 sm:flex-1">
          <ListOption
            Icon={Union}
            name="Union"
            selected={listType === "UNION"}
            onClick={() => onListTypeSelect("UNION")}
          />
        </div>

        <div className="flex-none w-2/5 ml-4 sm:flex-1">
          <ListOption
            Icon={Intersect}
            name="Intersect"
            selected={listType === "INTERSECT"}
            onClick={() => onListTypeSelect("INTERSECT")}
          />
        </div>

        <div className="flex-none w-2/5 ml-4 sm:flex-1">
          <ListOption
            Icon={Subtract}
            name="Subtract"
            selected={listType === "SUBTRACT"}
            onClick={() => onListTypeSelect("SUBTRACT")}
          />
        </div>

        <div className="flex-none w-2/5 ml-4 sm:flex-1">
          <ListOption
            Icon={Difference}
            name="Difference"
            selected={listType === "DIFFERENCE"}
            onClick={() => onListTypeSelect("DIFFERENCE")}
          />
        </div>

        {/* HACK (jake): gives some padding for scroll */}
        <div className="ml-2 sm:hidden" style={{ fontSize: "1px" }}>
          &nbsp;
        </div>
      </div>

      {renderFormOrUpgradeMaybe()}

      {preview && (
        <PreviewListDialog
          preview={preview}
          onCancel={clearPreview}
          onCreate={onCreateProxy}
        />
      )}
    </Card>
  );

  function renderFormOrUpgradeMaybe() {
    if (listType === null) return null;

    const isPremiumListType = PREMIUM_LIST_TYPES.includes(listType);
    const showForm = allowCreation && (allowPremiumLists || !isPremiumListType);

    return (
      <div className="px-6 pb-6">
        {showForm ? (
          <NewListForm
            listType={listType}
            initialSubjects={initialSubjects}
            onSubmit={onPreview}
          />
        ) : (
          <SubscriptionUpgrade
            background="davys"
            context={allowCreation ? "premiumList" : "moreLists"}
          />
        )}
      </div>
    );
  }

  function onListTypeSelect(type: ListType) {
    setListType(type === listType ? null : type);
  }

  async function onPreview(listType: ListType, input: string) {
    try {
      const { preview } = await previewList(listType, input);

      setPreview(preview);
    } catch (e) {
      addToast({ type: "error", body: e.message });
    }
  }

  async function onCreateProxy(listType: string, input: string) {
    await onCreate(listType, input);
    setListType(null);
    clearPreview();
  }

  function clearPreview() {
    setPreview(null);
  }
}
