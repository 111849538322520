import { ListType, SyncFrequency } from "@prisma/client";
import { AppShell } from "client/components/AppShell";
import { Loader } from "client/components/Loader";
import { useToasts } from "client/components/Toast";
import { useAuth } from "client/lib/hooks/useAuth";
import { useLists } from "client/lib/hooks/useLists";
import {
  createList,
  createListSyncRequest,
  unlinkList,
  updateList,
} from "client/lib/mutations";
import React from "react";
import { Nullable } from "universal/types/utils";
import { ListManager } from "./ListManager";
import { NewListCta } from "./NewListCta";
import { useRevalidateListsWhenSyncing } from "./useRevalidateListsWhenSyncing";

type Props = {
  listType: Nullable<ListType>;
  subjects: string;
};

export function AppPage(props: Props) {
  const { listType, subjects } = props;

  const { loading: loadingSelf, data } = useAuth();

  const user = data?.user;

  // TODO (jake): error
  const {
    loading: loadingLists,
    lists,
    paginate,
    mutate,
    mutatePrepend,
    mutateUpdate,
    mutateRemove,
  } = useLists(user?.id);

  const { addToast } = useToasts();

  useRevalidateListsWhenSyncing(lists, mutate);

  const activeSubscription = !!user?.subscription.active;

  return <AppShell>{renderBody()}</AppShell>;

  function renderBody() {
    const allowListCreation = !lists || lists.length < 1 || activeSubscription;
    const allowPremiumLists = activeSubscription;

    if (loadingSelf || loadingLists) return <Loader />;

    return (
      <React.Fragment>
        {renderOnboardingWelcomeMaybe()}
        <NewListCta
          initialListType={listType || null}
          initialSubjects={subjects}
          allowCreation={allowListCreation}
          allowPremiumLists={allowPremiumLists}
          onCreate={onCreateList}
        />
        {renderListManagerMaybe()}
      </React.Fragment>
    );
  }

  function renderOnboardingWelcomeMaybe() {
    if (lists && lists.length > 0) return null;

    return (
      <div className="mb-16">
        <h1 className="inline bg-davys text-xl text-cultured font-bold leading-tight sm:text-2xl md:text-4xl">
          <span className="px-2">Hey! Welcome to Vicariously</span>
        </h1>

        <div className="mt-8 sm:text-lg">
          There's not much to getting started with Vicariously. Choose a list
          type, enter some Twitter users, and voilà, you have your first
          Vicariously list!
        </div>
      </div>
    );
  }

  function renderListManagerMaybe() {
    if (!lists || lists.length === 0) return null;

    return (
      <div className="mt-16">
        <ListManager
          lists={lists || []}
          activeSubscription={activeSubscription}
          onPaginate={paginate}
          onChangeSyncFrequency={onChangeSyncFrequency}
          onUnlink={onUnlinkList}
          onForceSync={onForceSync}
        />
      </div>
    );
  }

  async function onCreateList(listType: string, input: string) {
    try {
      const { list } = await createList(listType, input);
      await mutatePrepend(list);

      addToast({ type: "success", body: "Your list is syncing, enjoy!" });
    } catch (e) {
      addToast({ type: "error", body: e.message });
    }
  }

  async function onChangeSyncFrequency(
    listId: string,
    syncFrequency: SyncFrequency
  ) {
    try {
      const { list } = await updateList(listId, syncFrequency);
      await mutateUpdate(list);

      addToast({
        type: "success",
        body: `Your list's sync frequency has been updated`,
      });
    } catch (e) {
      addToast({ type: "error", body: e.message });
    }
  }

  async function onUnlinkList(listId: string, destroy: boolean) {
    try {
      const { list } = await unlinkList(listId, destroy);
      await mutateRemove(list);

      const action = destroy ? "unlinked and deleted from Twitter" : "unlinked";
      addToast({ type: "success", body: `Your list has been ${action}` });
    } catch (e) {
      addToast({ type: "error", body: e.message });
    }
  }

  async function onForceSync(listId: string) {
    try {
      const { list } = await createListSyncRequest(listId);
      await mutateUpdate(list);

      addToast({ type: "success", body: "Your list will be synced shortly" });
    } catch (e) {
      addToast({ type: "error", body: e.message });
    }
  }
}
