import ReactModal from "react-modal";

interface Props extends ReactModal.Props {
  children: React.ReactNode;
}

ReactModal.setAppElement("body");

export function Modal(props: Props) {
  return <ReactModal {...props} />;
}
