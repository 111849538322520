import { ListType } from "@prisma/client";
import { getQueryParam } from "api/utils/next";
import { NextPage } from "client/components/NextPage";
import { AppPage } from "client/pages/AppPage";
import { useRouter } from "next/router";
import React from "react";
import { LinkProps } from "universal/types/app";
import { toAbsoluteUrl } from "universal/utils/url";

type LinkPropsOpts = {
  type: ListType;
  subject?: string[];
};

export function linkProps(opts?: LinkPropsOpts): LinkProps {
  let query = "";

  if (opts) {
    query = `?type=${opts.type.toLowerCase()}`;
    query = opts.subject
      ? `${query}&subject=${encodeURIComponent(opts.subject.join(" "))}`
      : query;
  }

  return { href: `/app${query}` };
}

export default function Page() {
  const router = useRouter();

  const listType: any = getQueryParam(router.query.type)?.toUpperCase();
  const subjects = getQueryParam(router.query.subject) || "";

  return (
    <NextPage
      seo={{
        title: "Vicariously",
        noindex: true,
        canonical: toAbsoluteUrl(linkProps()),
      }}
      PageImpl={AppPage}
      pageProps={{ listType, subjects }}
    />
  );
}
