import { CheckLine } from "client/components/icons";
import styles from "./RadioField.module.css";

type Props = React.ComponentProps<"input"> & {
  label: React.ReactNode;
  border: boolean;
};

export function RadioField(props: Props) {
  const { label, border, checked, ...rest } = props;

  return (
    <label className="relative flex items-center cursor-pointer">
      <input
        className={`absolute top-0 left-0 h-0 w-0 opacity-0 ${styles.input}`}
        type="radio"
        checked={checked}
        {...rest}
      />

      <div className={getRadioClasses(border)} />
      <div className="ml-2">{label}</div>
    </label>
  );
}

function getRadioClasses(border: boolean) {
  return [
    styles.radio,
    "w-6 h-6 rounded-full bg-cultured border-[3px] text-cultured flex justify-center items-center",
    border ? "border-davys" : "border-cultured",
  ].join(" ");
}
