import { NextPage } from "client/components/NextPage";
import { SettingsPage } from "client/pages/SettingsPage";
import React from "react";
import { LinkProps } from "universal/types/app";
import { brandTitle } from "universal/utils/seo";
import { toAbsoluteUrl } from "universal/utils/url";

export function linkProps(): LinkProps {
  return { href: "/settings" };
}

const TITLE = brandTitle("Settings");

export default function Page() {
  const canonical = toAbsoluteUrl(linkProps());

  return (
    <NextPage
      seo={{ title: TITLE, canonical, noindex: true }}
      PageImpl={SettingsPage}
      pageProps={{}}
    />
  );
}
