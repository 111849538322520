import Image from "next/image";
import styles from "./BlogPostImage.module.css";

type Props = React.ComponentProps<typeof Image>;

export function BlogPostImage(props: Props) {
  return (
    <figure className={`p-4 ${styles.checkeredBackground}`}>
      {/* HACK (jake): avoid bottom spacing on image by setting font-size to 0 https://github.com/vercel/next.js/issues/18911#issuecomment-723362202 */}
      {/* HACK (jake): need to override top margin set by tailwind prose */}
      <div className="mx-auto max-w-md text-[0px]" style={{ marginTop: 0 }}>
        <Image {...props} />
      </div>
    </figure>
  );
}
