import { Container } from "client/components/Container";
import { ExternalLink, Link } from "client/components/Link";
import { linkProps as blogIndexLinkProps } from "pages/blog/index";
import { linkProps as faqLinkProps } from "pages/faq";

interface Props {}

export function Footer(props: Props) {
  return (
    <div className="bg-davys text-cultured">
      <Container>
        <div className="py-4 flex justify-between">
          <div className="">
            <ExternalLink
              className="block text-lg font-bold link-blend"
              href="https://twitter.com/getvicarious"
            >
              @getvicarious
            </ExternalLink>

            <ExternalLink
              className="block mt-3 link-blend"
              href="https://twitter.com/messages/compose?recipient_id=948493988897554433"
            >
              Support
            </ExternalLink>

            <Link className="block mt-3 link-blend" {...blogIndexLinkProps()}>
              Blog
            </Link>

            <Link className="block mt-3 link-blend" {...faqLinkProps()}>
              FAQs
            </Link>
          </div>

          <div className="text-right text-sm">
            <div>© {new Date().getFullYear()} Vicariously</div>
          </div>
        </div>
      </Container>
    </div>
  );
}
