import { ButtonBase } from "client/components/Button";

type Props = {
  Icon: React.FunctionComponent<{ className: string }>;
  name: string;
  selected: boolean;
  onClick: () => void;
};

export function ListOption(props: Props) {
  const { Icon, name, selected, onClick } = props;

  const bgClass = selected ? "bg-pink" : "bg-cultured";

  return (
    <ButtonBase
      className={`w-full p-3 rounded-md text-davys outline-none ring-pink hover:ring-8 focus-visible:ring-8 active:relative active:top-[1px] ${bgClass}`}
      onClick={onClick}
    >
      <Icon className="h-10 mx-auto" />

      <div className="mt-2 font-bold">{name}</div>
    </ButtonBase>
  );
}
