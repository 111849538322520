import { SyncFrequency } from "@prisma/client";
import { AlertBox } from "client/components/AlertBox";
import { Dialog } from "client/components/Dialog";
import { ExternalLink } from "client/components/Link";
import { addDays, addMonths, addWeeks, format, formatDistance } from "date-fns";
import { linkProps as faqLinkProps } from "pages/faq";
import { API } from "universal/types/api";
import { isAbandonedSyncState } from "./utils";

interface Props {
  syncFrequency: SyncFrequency;
  list: API.List;
  onClose: () => void;
}

export function SyncDetailsDialog(props: Props) {
  const { syncFrequency, list, onClose } = props;

  return (
    <Dialog onClose={onClose}>
      <div className="text-2xl font-bold">
        Sync status: {renderSyncHeadline(list)}
      </div>

      {renderWarningMaybe(list)}

      <div className="mt-4">{renderSyncFrequency(list, syncFrequency)}</div>

      <div className="mt-4">{renderSyncStatus(list, syncFrequency)}</div>
    </Dialog>
  );
}

function renderSyncHeadline(list: API.List) {
  const { lastSync: sync } = list;

  if (isAbandonedSyncState(list)) {
    return <span className="text-error">We've given up</span>;
  }

  switch (sync.status) {
    case "SUCCEEDED":
      return <span className="text-success">All systems go</span>;

    case "AT_CAPACITY":
      return <span className="text-warning">Max list size reached</span>;

    case "THROTTLED":
      return <span className="text-warning">Throttled</span>;

    case "FAILED":
      return <span className="text-error">Technical difficulties</span>;

    case "IN_PROGRESS":
      return <span className="text-pink">In progress</span>;
  }
}

function renderSyncFrequency(list: API.List, syncFrequency: SyncFrequency) {
  if (isAbandonedSyncState(list)) return null;

  switch (syncFrequency) {
    case "NEVER":
      return (
        <span>
          Auto syncs are <span className="font-bold">disabled</span>.
        </span>
      );

    case "DAILY":
      return (
        <span>
          Auto syncs are <span className="font-bold">enabled</span> and the sync
          frequency is <span className="font-bold">daily</span>.
        </span>
      );

    case "WEEKLY":
      return (
        <span>
          Auto syncs are <span className="font-bold">enabled</span> and the sync
          frequency is <span className="font-bold">weekly</span>.
        </span>
      );

    case "MONTHLY":
      return (
        <span>
          Auto syncs are <span className="font-bold">enabled</span> and the sync
          frequency is <span className="font-bold">monthly</span>.
        </span>
      );
  }
}

function renderSyncStatus(list: API.List, syncFrequency: SyncFrequency) {
  const { lastSync: sync } = list;

  if (list.abandonedAt && isAbandonedSyncState(list)) {
    const abandonedAt = new Date(list.abandonedAt);

    return (
      <span>
        As of <span className="font-bold">{formatTime(abandonedAt)}</span> on{" "}
        <span className="font-bold">{formatDate(abandonedAt)}</span> we have
        stopped trying to automatically sync this list due to repeated failures.
        If you believe the reasons for these failures have been cleared up, you
        can force sync this list to return to normal. See the{" "}
        <ExternalLink
          className="link"
          href={`${faqLinkProps().href}#abandoned`}
        >
          FAQs
        </ExternalLink>{" "}
        for more details.
      </span>
    );
  }

  if (sync.succeededAt) {
    const succeededAt = new Date(sync.succeededAt);

    return (
      <span>
        The last sync succeeded on{" "}
        <span className="font-bold">{formatDate(succeededAt)}</span> at{" "}
        <span className="font-bold">{formatTime(succeededAt)}</span>.{" "}
        {renderNextSyncEstimateMaybe(succeededAt, syncFrequency)}
      </span>
    );
  }

  if (sync.failedAt) {
    const failedAt = new Date(sync.failedAt);

    return (
      <span>
        The last sync failed on{" "}
        <span className="font-bold">{formatDate(failedAt)}</span> at{" "}
        <span className="font-bold">{formatTime(failedAt)}</span>. This could be
        due to intermittent Twitter or Vicariously issues. Another sync will be
        attempted shortly.
      </span>
    );
  }

  return (
    <span>
      Vicariously is currently hard at work syncing this list. This may take up
      to a few minutes, check back shortly!
    </span>
  );
}

function renderWarningMaybe(list: API.List) {
  const { lastSync: sync } = list;

  if (isAbandonedSyncState(list)) return null;

  switch (sync.status) {
    case "AT_CAPACITY":
      return (
        <div className="mt-4">
          <AlertBox type="warning" size="md">
            This list has reached 5,000 members which is the{" "}
            <ExternalLink
              className="link"
              href="https://help.twitter.com/en/using-twitter/twitter-lists-not-working"
            >
              maximum amount allowed by Twitter
            </ExternalLink>
            .
          </AlertBox>
        </div>
      );

    case "THROTTLED":
      return (
        <div className="mt-4">
          <AlertBox type="warning" size="md">
            The Twitter API has an undocumented limitation on how many users you
            can add to a list in a given day. If you're seeing this, that means
            you've exceeded the daily limit.
            <br />
            <br />
            Since Vicariously syncs lists on a regular basis, this should
            eventually work itself out. Depending on the number of lists you
            have in this state, it could take anywhere from 24 hours to multiple
            days for all of them to get properly synced.
          </AlertBox>
        </div>
      );

    default:
      return null;
  }
}

function renderNextSyncEstimateMaybe(date: Date, syncFrequency: SyncFrequency) {
  if (syncFrequency === "NEVER") return null;

  return (
    <span>
      The next sync will be attempted in{" "}
      <span className="font-bold">
        {formatNextSyncDistance(date, syncFrequency)}
      </span>
      .
    </span>
  );
}

function formatDate(date: Date) {
  return format(date, "MMMM do, yyyy");
}

function formatTime(date) {
  return format(date, "h:mm aaa");
}

function formatNextSyncDistance(date: Date, syncFrequency: SyncFrequency) {
  const nextSyncEstimate = getNextSyncEstimate(date, syncFrequency);

  return formatDistance(new Date(), nextSyncEstimate);
}

function getNextSyncEstimate(date: Date, syncFrequency: SyncFrequency) {
  switch (syncFrequency) {
    case "DAILY":
      return addDays(date, 1);

    case "WEEKLY":
      return addWeeks(date, 1);

    case "MONTHLY":
      return addMonths(date, 1);

    case "NEVER":
      return new Date();
  }
}
