import { Button } from "client/components/Button";
import { Loader4Line } from "client/components/icons";
import { useFormState } from "react-final-form";

type Props = Omit<React.ComponentProps<typeof Button>, "type"> & {
  allowPristineSubmit?: boolean;
};

export function SubmitButton(props: Props) {
  const {
    allowPristineSubmit,
    disabled: disabledProp,
    children,
    ...rest
  } = props;

  const { pristine, submitting, errors } = useFormState();

  const hasErrors = errors && Object.keys(errors).length > 0;
  const disabled =
    (!allowPristineSubmit && pristine) || hasErrors || disabledProp;

  const childrenContainerClassName = submitting ? "invisible" : "";

  const loaderContainerClassName = [
    "absolute inset-0 flex justify-center items-center",
    submitting ? "" : "hidden",
  ].join(" ");

  return (
    <Button type="submit" disabled={disabled} disconnect={submitting} {...rest}>
      <div className="relative">
        <div className={childrenContainerClassName}>{children}</div>
        <div className={loaderContainerClassName}>
          <Loader4Line className="w-5 h-5 fill-current spin" />
        </div>
      </div>
    </Button>
  );
}
