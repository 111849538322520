import { SubscriptionUpgrade } from "client/components/SubscriptionUpgrade";
import React from "react";
import { SectionCard } from "./SectionCard";

type Props = {};

export function SubscriptionCard(props: Props) {
  return (
    <SectionCard title="Subscription">
      <SubscriptionUpgrade background="davys" context="moreLists" />
    </SectionCard>
  );
}
