import { BlogPostShell } from "client/components/BlogPostShell";
import { NextPage } from "client/components/NextPage";
import { EmbeddedTweet } from "client/components/EmbeddedTweet";
import { ExternalLink } from "client/components/Link";
import React from "react";
import { LinkProps } from "universal/types/app";
import { blogMetadataToSeo } from "universal/utils/blog";

export const meta = {
  title: "Origins",
  description:
    "Learn about the innocuous origins of Vicariously and how it ended up launching in July 2020.",
  publishedAt: new Date("2021-01-18T18:42:05.951Z"),
  path: "/blog/origins",
};

export function linkProps(): LinkProps {
  return { href: meta.path };
}

export default function Page() {
  return (
    <NextPage
      seo={blogMetadataToSeo(meta)}
      PageImpl={BlogPage}
      pageProps={{}}
    />
  );
}

function BlogPage() {
  return (
    <BlogPostShell meta={meta}>
      <p>
        The idea for Vicariously has been on my list of "things I should build,
        but will probably never get around to and hate myself for it" for years.
        I thought the idea was simple, clever, and original – everything you'd
        want out of a fun side project. I would later learn that it was only
        simple and clever at best.
      </p>

      <EmbeddedTweet className="flex my-6 justify-center">
        <blockquote className="twitter-tweet">
          <p lang="en" dir="ltr">
            <a href="https://twitter.com/JakeHarding?ref_src=twsrc%5Etfw">
              @JakeHarding
            </a>{" "}
            I imagine you already know this, but the very first version of
            twitter had{" "}
            <a href="https://twitter.com/getvicarious?ref_src=twsrc%5Etfw">
              @getvicarious
            </a>
            &#39;s functionality built in-
            <a href="https://twitter.com/jack?ref_src=twsrc%5Etfw">@jack</a> had
            it so everyone&#39;s home timeline was available a click or so away
            from their profile. I only learned this YEARS into my time at
            twitter.
          </p>
          &mdash; matt knox (@mattknox){" "}
          <a href="https://twitter.com/mattknox/status/1282157672314179584?ref_src=twsrc%5Etfw">
            July 12, 2020
          </a>
        </blockquote>
      </EmbeddedTweet>

      <p>
        In January 2018 (probably while all my friends were partaking in a sober
        January and my social life took a nosedive), I purchased the
        vicariously.io domain and built a prototype. It worked just as I
        imagined, but using lists on the Twitter iOS client was such a pain I
        quickly lost interest in the project and it was left to collect dust
        with all my other abandoned side projects.
      </p>

      <p>
        Fast-forward to July 2020 and I found myself barely using Twitter due to
        my home timeline being so miserable. I never used to miss a tweet; Now,
        I rarely opened the app. Granted, this could have just been a{" "}
        <ExternalLink href="https://en.wikipedia.org/wiki/2020_United_States_presidential_election">
          reflection of the time
        </ExternalLink>
        , but I believe it was more due to poor curation on my part. I took a
        peek at the lists I had previously made with the Vicariously prototype
        to put this theory to the test. Lo and behold, I enjoyed the content on
        those lists! Twitter was fun again! On top of that, I discovered it was
        now super easy to access lists through the Twitter app.
      </p>

      <EmbeddedTweet className="flex my-6 justify-center">
        <blockquote className="twitter-tweet" data-conversation="none">
          <p lang="en" dir="ltr">
            On our list? Make lists even better for you! ✅<br />
            <br />
            Now on iOS, you can pin up to five lists, rearrange them, and swipe
            to access from home.{" "}
            <a href="https://t.co/gNdfNE1DCl">pic.twitter.com/gNdfNE1DCl</a>
          </p>
          &mdash; Twitter (@Twitter){" "}
          <a href="https://twitter.com/Twitter/status/1176225981067071489?ref_src=twsrc%5Etfw">
            September 23, 2019
          </a>
        </blockquote>
      </EmbeddedTweet>

      <p>
        With a renewed interest in Vicariously and an assist from COVID-19 to
        free up all my weekends, I was now determined to build and deploy a tool
        I knew would upgrade my Twitter experience. The initial feature set was
        simple:
      </p>

      <ul>
        <li>Log in with Twitter</li>
        <li>
          Create a list and populate it with the follows of a given Twitter user
        </li>
        <li>Sync the created lists daily to ensure they stayed up to date</li>
      </ul>

      <p>
        For once in my life, I finally had a weekend project that could be
        completed in a weekend. Using{" "}
        <ExternalLink href="https://nextjs.org/">NextJS</ExternalLink> and{" "}
        <ExternalLink href="https://tailwindcss.com/">Tailwind</ExternalLink>, I
        was able to stand up a simple, functional UI in a few hours. I had
        already completed the backend logic two years earlier when I built the
        initial prototype, so implementing that was just a matter of porting it
        over to my new project. The only thing I had to put thought into was how
        I wanted to implement the daily syncs. For side projects, simple is
        always better, so I created a worker using{" "}
        <ExternalLink href="https://github.com/node-cron/node-cron">
          node-cron
        </ExternalLink>{" "}
        and spun up a DigitalOcean droplet to run it on for $5/month.{" "}
      </p>

      <p>
        Vicariously 1.0 was complete and available for the world to use. Did I
        think anyone other than myself would use it? No, not really. But hey, if
        you spend a weekend building something, you might as well tweet about it
        to make the launch official.
      </p>

      <EmbeddedTweet className="flex my-6 justify-center">
        <blockquote className="twitter-tweet">
          <p lang="en" dir="ltr">
            Been thinking about ways to overhaul my Twitter experience so I
            built <a href="https://t.co/bKZdkbM88a">https://t.co/bKZdkbM88a</a>{" "}
            and I have to say, I&#39;m pretty happy with the end results.
            <br />
            <br />
            It&#39;s an easy way to create (and sync) lists based on the follows
            of another user.
          </p>
          &mdash; Jake (@JakeHarding){" "}
          <a href="https://twitter.com/JakeHarding/status/1283826664502247424?ref_src=twsrc%5Etfw">
            July 16, 2020
          </a>
        </blockquote>
      </EmbeddedTweet>

      <p>
        I never expected that all this would lead to Vicariously having its 15
        minutes of fame in only a matter of weeks, but that's a blog post for
        another day.
      </p>
    </BlogPostShell>
  );
}
