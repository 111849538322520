import { useField } from "react-final-form";
import { RadioField as RadioFieldControl } from "client/components/controls";

type Props = Omit<React.ComponentProps<typeof RadioFieldControl>, "checked"> & {
  name: string;
};

export function RadioField(props: Props) {
  const { name, disabled: disabledProp } = props;

  const { input, meta } = useField(name, { type: "radio", value: props.value });
  const disabled = meta.submitting || disabledProp;

  return <RadioFieldControl disabled={disabled} {...props} {...input} />;
}
