import { useApi } from "client/lib/hooks/useApi";
import { useEffect } from "react";
import { setUserId } from "universal/lib/monitoring";
import { API } from "universal/types/api";
import { Nullable } from "universal/types/utils";

export type Data = {
  user: Nullable<API.UserSelf>;
};

export function useAuth() {
  const api = useApi<Data>("/api/users/self");

  useEffect(() => {
    setUserId(api.data?.user?.id || null);
  }, [api.data?.user?.id]);

  return api;
}
