interface Props {
  className: string;
}

export function Film(props: Props) {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 360 360"
    >
      <g fill="none" fillRule="evenodd">
        <circle
          stroke="#50514F"
          strokeWidth="20"
          fill="#FAF9F7"
          cx="180"
          cy="180"
          r="170"
        />
        <g transform="translate(37 38)" fill="#50514F">
          <rect x="125.634" width="35.811" height="40.912" rx="9.66" />
          <path d="M213.597 18.133l13.831 8.975a9.66 9.66 0 012.843 13.362l-.002.003-11.77 18.107a9.66 9.66 0 01-13.361 2.84l-13.833-8.972a9.66 9.66 0 01-2.842-13.362l.002-.003 11.77-18.107a9.66 9.66 0 0113.36-2.84zM269.357 75.495l6.97 14.935a9.66 9.66 0 01-4.671 12.838l-.003.001-19.583 9.123a9.66 9.66 0 01-12.836-4.672l-6.972-14.933a9.66 9.66 0 014.67-12.838l.003-.001 19.584-9.123a9.66 9.66 0 0112.835 4.672zM285.47 154.18l-2.296 16.318a9.66 9.66 0 01-10.91 8.221l-21.397-3.004a9.66 9.66 0 01-8.222-10.91l2.297-16.319a9.66 9.66 0 0110.91-8.22l21.396 3.003a9.66 9.66 0 018.222 10.91v.001zM256.41 229.077l-10.82 12.437a9.66 9.66 0 01-13.625.953l-16.309-14.164a9.66 9.66 0 01-.955-13.627l.002-.003 10.822-12.434a9.66 9.66 0 0113.625-.953l16.31 14.163a9.66 9.66 0 01.955 13.628l-.002.003zM191.41 276.346l-15.853 4.542a9.66 9.66 0 01-11.947-6.622l-5.958-20.756a9.66 9.66 0 016.622-11.949h.002l15.853-4.54a9.66 9.66 0 0111.947 6.622l5.957 20.756a9.66 9.66 0 01-6.622 11.949h-.001zM111.522 280.89l-15.852-4.542a9.66 9.66 0 01-6.625-11.948v-.001l5.958-20.756a9.66 9.66 0 0111.948-6.622l15.853 4.54a9.66 9.66 0 016.623 11.95l-5.957 20.755a9.66 9.66 0 01-11.947 6.622zM41.486 241.516l-10.82-12.436a9.66 9.66 0 01.954-13.63l16.309-14.164a9.66 9.66 0 0113.625.953l10.822 12.434a9.66 9.66 0 01-.953 13.63l-16.31 14.164a9.66 9.66 0 01-13.625-.953zM3.904 170.498L1.61 154.18a9.66 9.66 0 018.221-10.91l21.398-3.005a9.66 9.66 0 0110.91 8.221l2.296 16.318a9.66 9.66 0 01-8.222 10.911l-21.397 3.004a9.66 9.66 0 01-10.91-8.22zM10.755 90.431l6.97-14.934a9.66 9.66 0 0112.835-4.672l19.584 9.123a9.66 9.66 0 014.674 12.837v.002l-6.973 14.933a9.66 9.66 0 01-12.835 4.672l-19.584-9.123a9.66 9.66 0 01-4.673-12.839zM59.653 27.11l13.83-8.974a9.66 9.66 0 0113.361 2.84l11.77 18.107a9.66 9.66 0 01-2.84 13.365L81.941 61.42a9.66 9.66 0 01-13.36-2.84l-11.77-18.107a9.66 9.66 0 012.84-13.365z" />
        </g>
      </g>
    </svg>
  );
}
