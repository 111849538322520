import { config } from "config/public";
import { LinkProps } from "universal/types/app";

export function toAbsoluteUrl(pathOrLinkProps: string | LinkProps) {
  if (typeof pathOrLinkProps === "string") {
    return `${config.BASE_URL}${pathOrLinkProps}`;
  }

  return `${config.BASE_URL}${pathOrLinkProps.href}`;
}
