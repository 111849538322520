interface Props {
  className: string;
}

export function BrandLogo(props: Props) {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 501 470"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(71)">
          <circle
            stroke="#50514F"
            strokeWidth="20"
            fill="#FAF9F7"
            cx="180"
            cy="180"
            r="170"
          />
          <g transform="translate(37 38)" fill="#50514F">
            <rect x="125.634" width="35.811" height="40.912" rx="9.66" />
            <path d="M213.597 18.133l13.831 8.975a9.66 9.66 0 012.843 13.362l-.002.003-11.77 18.107a9.66 9.66 0 01-13.361 2.84l-13.833-8.972a9.66 9.66 0 01-2.842-13.362l.002-.003 11.77-18.107a9.66 9.66 0 0113.36-2.84zM269.357 75.495l6.97 14.935a9.66 9.66 0 01-4.671 12.838l-.003.001-19.583 9.123a9.66 9.66 0 01-12.836-4.672l-6.972-14.933a9.66 9.66 0 014.67-12.838l.003-.001 19.584-9.123a9.66 9.66 0 0112.835 4.672zM285.47 154.18l-2.296 16.318a9.66 9.66 0 01-10.91 8.221l-21.397-3.004a9.66 9.66 0 01-8.222-10.91l2.297-16.319a9.66 9.66 0 0110.91-8.22l21.396 3.003a9.66 9.66 0 018.222 10.91v.001zM256.41 229.077l-10.82 12.437a9.66 9.66 0 01-13.625.953l-16.309-14.164a9.66 9.66 0 01-.955-13.627l.002-.003 10.822-12.434a9.66 9.66 0 0113.625-.953l16.31 14.163a9.66 9.66 0 01.955 13.628l-.002.003zM191.41 276.346l-15.853 4.542a9.66 9.66 0 01-11.947-6.622l-5.958-20.756a9.66 9.66 0 016.622-11.949h.002l15.853-4.54a9.66 9.66 0 0111.947 6.622l5.957 20.756a9.66 9.66 0 01-6.622 11.949h-.001zM111.522 280.89l-15.852-4.542a9.66 9.66 0 01-6.625-11.948v-.001l5.958-20.756a9.66 9.66 0 0111.948-6.622l15.853 4.54a9.66 9.66 0 016.623 11.95l-5.957 20.755a9.66 9.66 0 01-11.947 6.622zM41.486 241.516l-10.82-12.436a9.66 9.66 0 01.954-13.63l16.309-14.164a9.66 9.66 0 0113.625.953l10.822 12.434a9.66 9.66 0 01-.953 13.63l-16.31 14.164a9.66 9.66 0 01-13.625-.953zM3.904 170.498L1.61 154.18a9.66 9.66 0 018.221-10.91l21.398-3.005a9.66 9.66 0 0110.91 8.221l2.296 16.318a9.66 9.66 0 01-8.222 10.911l-21.397 3.004a9.66 9.66 0 01-10.91-8.22zM10.755 90.431l6.97-14.934a9.66 9.66 0 0112.835-4.672l19.584 9.123a9.66 9.66 0 014.674 12.837v.002l-6.973 14.933a9.66 9.66 0 01-12.835 4.672l-19.584-9.123a9.66 9.66 0 01-4.673-12.839zM59.653 27.11l13.83-8.974a9.66 9.66 0 0113.361 2.84l11.77 18.107a9.66 9.66 0 01-2.84 13.365L81.941 61.42a9.66 9.66 0 01-13.36-2.84l-11.77-18.107a9.66 9.66 0 012.84-13.365z" />
          </g>
        </g>
        <path
          d="M366.36 117.5c-36.495 13.156-75.595 20-115.727 20-40.131 0-79.231-6.844-115.726-20H68.684a2.5 2.5 0 00-2.462 2.064c-5.333 30.131-9.164 60.257-11.492 90.38l-.701 9.068-8.843 2.124a937.414 937.414 0 00-16.084 4.014 12.5 12.5 0 00-8.79 8.346C15.52 248.715 13.134 263.87 13.134 279c0 15.13 2.387 30.285 7.18 45.504a12.5 12.5 0 008.789 8.346 937.47 937.47 0 0015.931 3.978l8.861 2.13.683 9.088c2.356 31.379 6.342 62.761 11.958 94.148a2.5 2.5 0 002.1 2.034c60.672 8.848 121.336 13.272 181.998 13.272 60.662 0 121.327-4.424 181.999-13.272a2.5 2.5 0 002.1-2.034c5.616-31.387 9.602-62.77 11.958-94.148l.682-9.089 8.862-2.13a937.47 937.47 0 0015.93-3.977 12.5 12.5 0 008.79-8.346c4.793-15.219 7.18-30.374 7.18-45.504 0-15.13-2.387-30.285-7.18-45.504a12.5 12.5 0 00-8.79-8.346 937.406 937.406 0 00-16.083-4.014l-8.843-2.124-.701-9.067c-2.329-30.124-6.16-60.25-11.492-90.38a2.5 2.5 0 00-2.462-2.065H366.36z"
          stroke="#50514F"
          strokeWidth="25"
          fill="#F15BB5"
        />
        <g fill="#50514F">
          <path d="M107.794 229.935c47.613-6.623 95.226-9.935 142.84-9.935 47.612 0 95.225 3.312 142.838 9.935a13.846 13.846 0 0111.563 10.509c3.732 15.685 5.598 31.37 5.598 47.056 0 15.685-1.866 31.37-5.598 47.056a13.846 13.846 0 01-11.563 10.51C345.86 351.687 298.246 355 250.633 355s-95.226-3.312-142.839-9.935a13.846 13.846 0 01-11.562-10.509c-3.732-15.685-5.599-31.37-5.599-47.056 0-15.685 1.867-31.37 5.599-47.056a13.846 13.846 0 0111.562-10.51z" />
          <path d="M121.074 243.14c43.187-5.042 86.373-7.563 129.56-7.563 43.186 0 86.372 2.52 129.558 7.563a13.846 13.846 0 0111.775 10.19c3.032 11.39 4.549 22.78 4.549 34.17s-1.517 22.78-4.55 34.17a13.846 13.846 0 01-11.774 10.19c-43.186 5.042-86.372 7.563-129.559 7.563-43.186 0-86.372-2.52-129.559-7.563a13.846 13.846 0 01-11.774-10.19c-3.033-11.39-4.549-22.78-4.549-34.17s1.516-22.78 4.549-34.17a13.846 13.846 0 0111.774-10.19z" />
        </g>
      </g>
    </svg>
  );
}
