import { API } from "universal/types/api";

type Data = {
  list: API.List;
};
export async function createListSyncRequest(listId: string): Promise<Data> {
  const res = await fetch(`/api/lists/${listId}/sync-requests`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.error);
  }

  return json;
}
