import { BlogPostImage } from "client/components/BlogPostImage";
import { BlogPostShell } from "client/components/BlogPostShell";
import { NextPage } from "client/components/NextPage";
import { LinkProps } from "universal/types/app";
import { blogMetadataToSeo } from "universal/utils/blog";

export const meta = {
  title: "January 2021 Product Update",
  description: "See what's new on Vicariously in the month of January 2021.",
  path: "/blog/january-2021-product-update",
  publishedAt: new Date("2021-01-16T00:18:04.241Z"),
};

export function linkProps(): LinkProps {
  return { href: meta.path };
}

export default function Page() {
  return (
    <NextPage
      seo={blogMetadataToSeo(meta)}
      PageImpl={BlogPage}
      pageProps={{}}
    />
  );
}

function BlogPage() {
  return (
    <BlogPostShell meta={meta}>
      <p>
        It's a new year, so that means it's time for some new Vicariously
        features:
      </p>

      <ul>
        <li>
          <strong>Previews</strong>: Confirm who your list will be based on and
          get a heads up about any potential issues before creating your list.
        </li>
        <li>
          <strong>Sync Frequency Configuration</strong>: Configure your list to
          be synced daily, weekly, monthly, or even turn automated syncs off
          entirely.
        </li>
        <li>
          <strong>Force Syncs</strong>: Sync lists on demand.
        </li>
        <li>
          <strong>Pagination</strong>: Speeds up initial page load and
          subsequent actions by only loading a subset of your lists.
        </li>
      </ul>

      <h2>Previews</h2>

      <p>
        When you're just typing in Twitter handles to create a list, it's easy
        to make a dumb mistake. A typo or a misremembered handle can lead to an
        unwanted list. To help avoid this problem Vicariously now presents you
        with a preview of the list before creating it.
      </p>

      <BlogPostImage
        src="/imgs/blog/january-2021-product-update/preview.png"
        alt="List preview with 3 different users"
        width={750}
        height={920}
      />

      <p>
        Vicariously also will give you a heads up if it looks like there may be
        any potential issue with the list you're planning on creating. For
        example, if it seems like the list may exceed the max size allowed by
        Twitter.
      </p>

      <BlogPostImage
        src="/imgs/blog/january-2021-product-update/preview-warning.png"
        alt="List preview displaying a warning regarding max list size"
        width={750}
        height={1152}
      />

      <h2>Sync Frequency Configuration</h2>

      <p>
        By default, Vicariously syncs lists daily. For some use cases, this may
        not be ideal. For example, creating a snapshot of someone's follows for
        a particular moment in time.
      </p>

      <p>
        To meet a broader set of needs, you can now configure your list to sync
        on a schedule that works for you.
      </p>

      <BlogPostImage
        src="/imgs/blog/january-2021-product-update/sync-frequency.png"
        alt="Menu used to configure the sync frequency of a list"
        width={750}
        height={736}
      />

      <h2>Force Syncs</h2>

      <p>
        Occasionally you don't want to wait for the next scheduled sync and
        would prefer your list to be synced immediately. It's now possible to do
        just this by selecting the "Force sync" option from the "More options"
        menu.
      </p>

      <BlogPostImage
        src="/imgs/blog/january-2021-product-update/force-sync.png"
        alt="The more options menu where you can initiate a force sync"
        width={750}
        height={564}
      />

      <h2>Pagination</h2>

      <p>
        If you've created many lists using Vicariously, the page could be a
        little slow to load. Nobody enjoys a slow web page, so to fix this,
        lists are now paginated. Your latest ten lists will be loaded when you
        open the page – if you have more than ten lists, there's a "Load more"
        button to paginate through the rest.
      </p>

      <BlogPostImage
        src="/imgs/blog/january-2021-product-update/pagination.png"
        alt="Pagination button"
        width={750}
        height={1334}
      />
    </BlogPostShell>
  );
}
