import {
  ListSubjectTwitterUserContext,
  ListSubjectTwitterListContext,
} from "@prisma/client";
import { API } from "universal/types/api";

export function isAbandonedSyncState(list: API.List) {
  const { abandonedAt, lastSync } = list;

  return abandonedAt && lastSync.status !== "IN_PROGRESS";
}

export function getHumanTwitterUserContext(
  context: ListSubjectTwitterUserContext
): string {
  switch (context) {
    case "FOLLOWERS":
      return "Followers";

    case "FOLLOWS":
      return "Follows";
  }
}

export function getHumanTwitterListContext(
  context: ListSubjectTwitterListContext
): string {
  switch (context) {
    case "MEMBERS":
      return "Members";
  }
}
