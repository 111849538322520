interface Props {
  children: React.ReactNode;
}

export function Container(props: Props) {
  const { children } = props;
  return (
    <div className="px-6">
      <div className="max-w-screen-sm mx-auto">{children}</div>
    </div>
  );
}
