import { Avatar } from "client/components/Avatar";
import { FileList2Line } from "client/components/icons";
import { ExternalLink } from "client/components/Link";
import React from "react";
import { API } from "universal/types/api";
import {
  getHumanTwitterListContext,
  getHumanTwitterUserContext,
} from "../utils";

type Props = {
  subject: API.ListPreviewSubject;
};

export function PreviewSubject(props: Props) {
  const { subject } = props;

  const className = [
    "flex justify-between items-center",
    subject.denylist ? "text-warning" : "",
  ].join(" ");

  return (
    <div className={className}>
      <ExternalLink
        className="flex items-center overflow-hidden link-blend"
        href={subject.permalinkUrl}
      >
        <div className="flex-none">{renderAvatar()}</div>
        <div className="flex-1 ml-2 whitespace-nowrap overflow-hidden">
          <div className="overflow-hidden overflow-ellipsis">
            {subject.displayName}
          </div>
          <div className="text-xs text-davys">{getHumanContext(subject)}</div>
        </div>
      </ExternalLink>
      <div className="flex-none ml-2">{subject.count.toLocaleString()}</div>
    </div>
  );

  function renderAvatar() {
    switch (subject.type) {
      case "list":
        return (
          <div className="flex justify-center items-center w-[40px] h-[40px] border-2 border-davys rounded-md">
            <FileList2Line className="w-7 h-7 text-davys fill-current" />
          </div>
        );

      case "user":
        return (
          <Avatar
            user={{
              screenName: subject.displayName,
              profileImageUrl: subject.profileImageUrl,
            }}
            size={40}
            appearance="cultured"
          />
        );
    }
  }
}

function getHumanContext(subject: API.ListPreviewSubject) {
  switch (subject.type) {
    case "list":
      return getHumanTwitterListContext(subject.context);
    case "user":
      return getHumanTwitterUserContext(subject.context);
  }
}
