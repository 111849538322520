import { BlogPostImage } from "client/components/BlogPostImage";
import { BlogPostShell } from "client/components/BlogPostShell";
import { ExternalLink } from "client/components/Link";
import { NextPage } from "client/components/NextPage";
import { linkProps as appLinkProps } from "pages/app";
import React from "react";
import { LinkProps } from "universal/types/app";
import { blogMetadataToSeo } from "universal/utils/blog";

export const meta = {
  title: "Introducing New Subject Types",
  description:
    "It's now possible to create lists based on the followers of a user and the members of a list. Learn how it works and about some potential ways to utilize this new functionality.",
  path: "/blog/introducing-new-subject-types",
  publishedAt: new Date("2021-05-06T15:01:34.157Z"),
};

export function linkProps(): LinkProps {
  return { href: meta.path };
}

export default function Page() {
  return (
    <NextPage
      seo={blogMetadataToSeo(meta)}
      PageImpl={BlogPage}
      pageProps={{}}
    />
  );
}

function BlogPage() {
  return (
    <BlogPostShell meta={meta}>
      <p>
        Since Vicariously launched last summer, thousands of users have used it
        to level up their Twitter experience. The ability to create engaging
        lists instantly has proven to be an indispensable tool for discovery on
        Twitter. However, a limitation of Vicariously has always been you can
        only create lists based on the follows of users. How about we change
        that?
      </p>

      <p>
        It's now possible to create lists composed of the following subjects:
      </p>

      <ul>
        <li>Follows of a user</li>
        <li>Followers of a user 🎉</li>
        <li>Members of a list 🎉</li>
      </ul>

      <BlogPostImage
        src="/imgs/blog/introducing-new-subject-types/preview.png"
        alt="Preview of list using all subject types"
        width={1242}
        height={1488}
      />

      <p>
        You can mix and match these subjects to your heart's desire. At first
        blush, this might seem trivial, but it actually enables some neat use
        cases. Before I get to those use cases, let me explain how it works.
      </p>

      <h2>How it Works</h2>

      <p>
        To create a list on Vicariously, you provide a list of subjects.
        Historically, the only subject type was the follows of a user, so this
        was pretty simple. You'd enter a Twitter handle and it'd resolve to that
        user's follows.
      </p>

      <p>
        With the introduction of the new subject types, this gets a little more
        complicated, but it should still be straightforward. This new
        functionality is also entirely an additive change, so uninterested users
        do not need to modify their current usage.
      </p>

      <p>
        To reference the follows of a user, you will continue to provide the
        user's handle (e.g.{" "}
        <ExternalLink
          href={
            appLinkProps({ type: "CLASSIC", subject: ["@getvicarious"] }).href
          }
        >
          @getvicarious
        </ExternalLink>
        ). To reference the followers of a user, you will provide the user's
        handle prefixed with a plus sign (e.g.{" "}
        <ExternalLink
          href={
            appLinkProps({ type: "CLASSIC", subject: ["+@getvicarious"] }).href
          }
        >
          +@getvicarious
        </ExternalLink>
        ). To reference the members of a list, you will provide the list URL
        (e.g.{" "}
        <ExternalLink
          href={
            appLinkProps({
              type: "CLASSIC",
              subject: ["https://twitter.com/i/lists/1282895252655751168"],
            }).href
          }
        >
          https://twitter.com/i/lists/1282895252655751168
        </ExternalLink>
        ).
      </p>

      <p>
        There's a good chance the UI will be updated to make this more intuitive
        in the future. Until then, this is considered advanced functionality and
        something only power users will use.
      </p>

      <h2>Use Cases</h2>
      <p>
        As mentioned earlier, this enables some neat use cases. What follows
        isn't a comprehensive list, but it should give you an idea of some of
        the things that are now possible.
      </p>

      <h3>List of Lists</h3>
      <p>
        There are some highly curated lists on Twitter and now Vicariously
        offers you the ability to remix them. For example, if you're interested
        in venture capital, each of{" "}
        <ExternalLink href="https://twitter.com/ycombinator">
          @ycombinator
        </ExternalLink>
        ,{" "}
        <ExternalLink href="https://twitter.com/firstround">
          @firstround
        </ExternalLink>
        , and{" "}
        <ExternalLink href="https://twitter.com/sequoia">@sequoia</ExternalLink>{" "}
        maintain lists of their team members. It's now possible to{" "}
        <ExternalLink
          href={
            appLinkProps({
              type: "UNION",
              subject: [
                "https://twitter.com/i/lists/18149",
                "https://twitter.com/i/lists/1240048462005059584",
                "https://twitter.com/i/lists/79800345",
              ],
            }).href
          }
        >
          aggregate those three separate lists into a single list
        </ExternalLink>
        .
      </p>

      <BlogPostImage
        src="/imgs/blog/introducing-new-subject-types/list-of-lists.png"
        alt="Vicariously list composed of members of three different lists"
        width={1098}
        height={834}
      />

      <h3>Explore Followers</h3>
      <p>
        Have you ever been curious about what your followers are tweeting? You
        can now spin up a list containing all your followers and satisfy that
        curiosity. For example, here's a{" "}
        <ExternalLink href="https://twitter.com/i/lists/1386419489651130369">
          list of everyone that follows @getvicarious
        </ExternalLink>
        .
      </p>

      <h3>Copy List</h3>
      <p>
        Public lists on Twitter are a great way to share curated collections of
        users. However, you're at the mercy of another user to maintain the list
        and ensure it remains accessible. For mission-critical lists, a better
        solution would be to copy the list members into a list you own. If
        you've ever tried to do this, you know how painful it is. You have to
        add each user to your newly created list manually.
      </p>

      <p>
        Now you can use Vicariously to do this easily. All you have to do is
        create a list based on the target list. If you'd like to capture a
        snapshot of the target list and not have the list synced regularly, be
        sure to update the list's sync frequency to "never" or unlink the list
        from Vicariously.
      </p>

      <h3>[Your Use Case Here]</h3>
      <p>
        The above are just a few possible use cases. There are plenty of other
        ways users can utilize the new subject types and I can't wait to see
        them in the wild.
      </p>
    </BlogPostShell>
  );
}
