import { API } from "universal/types/api";

type Data = { user: API.UserSelf };

export async function updateUser(
  id: string,
  changes: API.UpdateUserChanges
): Promise<Data> {
  const res = await fetch(`/api/users/${id}`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(changes),
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.error);
  }

  return json;
}
