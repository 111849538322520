import { API } from "universal/types/api";
import Image from "next/image";

type Appearance = "cultured" | "davys";
type Size = 32 | 40 | 48 | 200;

type TwitterUserShape = Pick<API.TwitterUser, "screenName" | "profileImageUrl">;

type Props = {
  user: TwitterUserShape;
  appearance: Appearance;
  size: Size;
};

export function Avatar(props: Props) {
  const { user, appearance, size } = props;

  const alt = `@${user.screenName}'s Twitter profile image`;
  const classes = [
    "overflow-hidden",
    getAppearanceClasses(appearance),
    getSizeClasses(size),
  ].join(" ");

  return (
    <div className={classes}>
      {/* HACK (jake): use img tag to reduce image optimization calls for time being */}
      {/* <Image width={size} height={size} src={getSrc(user)} alt={alt} /> */}
      <img width={size} height={size} src={getSrc(user)} alt={alt} />
    </div>
  );
}

function getAppearanceClasses(appearance: Appearance) {
  switch (appearance) {
    case "cultured":
      return "border-davys";

    case "davys":
      return "border-none";
  }
}

function getSizeClasses(size: Size) {
  switch (size) {
    case 32:
      return "w-[32px] h-[32px] rounded-md border-2";

    case 40:
      return "w-[40px] h-[40px] rounded-md border-2";

    case 48:
      return "w-[48px] h-[48px] rounded-md border-2";

    case 200:
      return "w-[200px] h-[200px] rounded-lg border-8";
  }
}

function getSrc(user: TwitterUserShape) {
  return user.profileImageUrl.replace("_normal", "");
}
