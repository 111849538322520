import { API } from "universal/types/api";

type Data = API.FollowingStatus;

export async function followBrand(userId: string): Promise<Data> {
  const res = await fetch(`/api/users/${userId}/follow`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.error);
  }

  return json;
}
