import { MarketingShell } from "client/components/MarketingShell";

interface Props {
  children: React.ReactNode;
}

export function ResourceShell(props: Props) {
  const { children } = props;

  return (
    <MarketingShell>
      <div className="prose sm:prose-lg">{children}</div>
    </MarketingShell>
  );
}
