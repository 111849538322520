import { CheckLine } from "client/components/icons";
import styles from "./CheckboxField.module.css";

type Props = React.ComponentProps<"input"> & {
  label: React.ReactNode;
  border: boolean;
};

export function CheckboxField(props: Props) {
  const { label, border, checked, ...rest } = props;

  return (
    <label className="relative flex items-center cursor-pointer">
      <input
        className={`absolute top-0 left-0 h-0 w-0 opacity-0 ${styles.input}`}
        type="checkbox"
        checked={checked}
        {...rest}
      />

      <div className={getCheckboxClasses(border)}>
        <CheckLine className="w-6 h-6 fill-current" />
      </div>
      <div className="ml-2">{label}</div>
    </label>
  );
}

function getCheckboxClasses(border: boolean) {
  return [
    styles.checkbox,
    "rounded-md bg-cultured border-[3px] text-cultured flex justify-center items-center",
    border ? "border-davys" : "border-cultured",
  ].join(" ");
}
