import { VicariouslyApiResponse } from "api/types";
import { Maybe, Nullable } from "universal/types/utils";

export function getQueryParam(
  param: Maybe<string | string[]>
): Nullable<string> {
  if (param == null) return null;

  if (Array.isArray(param)) {
    return param[0];
  }

  return param;
}

export function redirectTo(
  res: VicariouslyApiResponse,
  code: 301 | 302,
  location: string
) {
  res.status(code);
  res.setHeader("Location", location);
  res.end();
}
