type Data = {
  url: string;
};

export async function createBillingPortalSession(): Promise<Data> {
  const res = await fetch("/api/stripe/billing-portal", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.error);
  }

  return json;
}
