import { getQueryParam } from "api/utils/next";
import { NextPage } from "client/components/NextPage";
import { LogInPage } from "client/pages/LogInPage";
import { useRouter } from "next/router";
import React from "react";
import { LinkProps } from "universal/types/app";
import { brandTitle } from "universal/utils/seo";
import { toAbsoluteUrl } from "universal/utils/url";

export function linkProps(redirectTo?: string): LinkProps {
  const query = redirectTo ? `?to=${encodeURIComponent(redirectTo)}` : "";
  return { href: `/log-in${query}` };
}

const TITLE = brandTitle("Log In");

export default function Page() {
  const canonical = toAbsoluteUrl(linkProps());
  const router = useRouter();

  const redirectTo = getQueryParam(router.query.to);

  return (
    <NextPage
      seo={{ title: TITLE, canonical }}
      PageImpl={LogInPage}
      pageProps={{ redirectTo }}
    />
  );
}
