import { Avatar } from "client/components/Avatar";
import { ExternalLink } from "client/components/Link";
import React from "react";
import { API } from "universal/types/api";
import { Awaitable } from "universal/types/utils";
import { BillingCard } from "./BillingCard";
import { NotificationSettingsCard } from "./NotificationSettingsCard";
import { PrivacyControlsCard } from "./PrivacyControlsCard";
import { SubscriptionCard } from "./SubscriptionCard";

type Props = {
  user: API.UserSelf;
  onUpdateNotificationSettings: (
    user: API.UserSelf,
    changes: API.NotificationSettings
  ) => Awaitable<void>;
  onUpdatePrivacyControls: (
    user: API.UserSelf,
    changes: API.PrivacyControls
  ) => Awaitable<void>;
};

export function SettingsPageBody(props: Props) {
  const { user, onUpdateNotificationSettings, onUpdatePrivacyControls } = props;

  return (
    <div>
      <div className="flex justify-center">
        <ExternalLink
          className="block link-keyboard"
          href={user.twitter.profileUrl}
        >
          <div className="flex justify-center">
            <Avatar user={user.twitter} size={200} appearance="cultured" />
          </div>

          <div className="mt-2 text-xl text-center font-bold ">
            @{user.twitter.screenName}
          </div>
        </ExternalLink>
      </div>

      {renderSubscriptionCardMaybe()}
      {renderBillingCardMaybe()}

      <div className="mt-16">
        <NotificationSettingsCard
          user={user}
          onSubmit={(changes) => onUpdateNotificationSettings(user, changes)}
        />
      </div>

      <div className="mt-16">
        <PrivacyControlsCard
          privacyControls={user.privacyControls}
          onSubmit={(changes) => onUpdatePrivacyControls(user, changes)}
        />
      </div>
    </div>
  );

  function renderSubscriptionCardMaybe() {
    if (user.subscription.active) return null;

    return (
      <div className="mt-16">
        <SubscriptionCard />
      </div>
    );
  }

  function renderBillingCardMaybe() {
    if (!user.subscription.billingPortalAccess) return null;

    return (
      <div className="mt-16">
        <BillingCard />
      </div>
    );
  }
}
