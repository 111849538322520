import { Film, Viewmaster } from "client/components/svgs";
import styles from "./Loader.module.css";

interface Props {}

export function Loader(props: Props) {
  return (
    <div className="my-32 flex justify-center">
      <div className="relative w-32 sm:w-48">
        <Film className={`${styles.film}`} />
        <Viewmaster className={`relative w-full ${styles.viewmaster}`} />
      </div>
    </div>
  );
}
