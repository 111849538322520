// import { formatTwitterUser } from "api/core/apiFormatters";
// import { getTwitterUser } from "api/core/getTwitterUser";
// import { getTwitterAPI } from "api/lib/twitter";
import { NextPage } from "client/components/NextPage";
import { IndexPage } from "client/pages/IndexPage";
import { InferGetStaticPropsType } from "next";
import React from "react";
import { LinkProps } from "universal/types/app";

export function linkProps(): LinkProps {
  return { href: "/" };
}

const TITLE =
  "Vicariously: Get out of your Twitter bubble and into someone else's";
const DESCRIPTION =
  "Vicariously provides an easy way to create Twitter lists that contain all the follows of another user. Vicariously syncs the lists regularly behind the scenes to ensure they're always up to date.";

type Props = InferGetStaticPropsType<typeof getStaticProps>;

export default function Page(props: Props) {
  return (
    <NextPage
      seo={{
        title: TITLE,
        description: DESCRIPTION,
        canonical: "https://vicariously.io",
        openGraph: {
          type: "website",
          url: "https://vicariously.io",
          title: TITLE,
          description: DESCRIPTION,
          images: [
            {
              url: "https://vicariously.io/imgs/og.png",
              width: 1200,
              height: 630,
            },
          ],
        },
      }}
      PageImpl={IndexPage}
      pageProps={{ samples: props.samples || [] }}
    />
  );
}

export async function getStaticProps(context) {
  // const examples = await Promise.all(
  //   [
  //     { listId: "1282895252655751168", ownerId: "58502284" },
  //     { listId: "1285665503545946113", ownerId: "41509142" },
  //     { listId: "1282443445320052736", ownerId: "3764301" },
  //     { listId: "1284246750136418304", ownerId: "474154291" },
  //     { listId: "1282442918096068608", ownerId: "66590132" },
  //     { listId: "1351374272594206720", ownerId: "44908387" },
  //   ].map(getSampleData)
  // );

  return {
    props: {
      samples: [],
    },
  };
}

type SampleParams = {
  listId: string;
  ownerId: string;
};

// async function getSampleData(params: SampleParams) {
//   const { listId, ownerId } = params;

//   const twitter = getTwitterAPI();

//   const twitterUser = await getTwitterUser({
//     twitter,
//     lookup: { type: "userId", userId: ownerId },
//   });

//   return {
//     listUrl: `https://twitter.com/i/lists/${listId}`,
//     twitterUser: formatTwitterUser(twitterUser),
//     twitterUserRaw: twitterUser.raw,
//   };
// }
