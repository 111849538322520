export namespace Card {
  export type Color = "davys" | "cultured";
  export type Padding = "0" | "4" | "6";
}

interface Props {
  color: Card.Color;
  padding: Card.Padding;
  children: React.ReactNode;
}

export function Card(props: Props) {
  const { color, padding, children } = props;

  const classes = [
    "rounded-lg",
    getColorClasses(color),
    getPaddingClasses(padding),
  ].join(" ");

  return <div className={classes}>{children}</div>;
}

function getColorClasses(color: Card.Color) {
  switch (color) {
    case "davys":
      return "bg-davys text-cultured";

    case "cultured":
      return "border-8 border-davys bg-cultured text-davys";
  }
}

function getPaddingClasses(padding: Card.Padding) {
  switch (padding) {
    case "0":
      return "";

    case "4":
      return "p-4";

    case "6":
      return "p-6";
  }
}
