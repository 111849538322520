import { differenceInMinutes, formatDistanceToNowStrict } from "date-fns";
import { useEffect, useState } from "react";
import { API } from "universal/types/api";

interface Props {
  list: API.List;
}

const TICK_MS = 60 * 1000;

export function SyncStatusText(props: Props) {
  const { list } = props;

  const [tick, setTick] = useState<any>();

  useEffect(() => {
    const interval = setInterval(() => setTick(new Date()), TICK_MS);

    return () => clearInterval(interval);
  }, []);

  return <span>{getText(list)}</span>;
}

function getText(list: API.List) {
  const { lastSync } = list;

  if (lastSync.succeededAt) {
    return `${agoText(lastSync.succeededAt)}`;
  }

  if (lastSync.failedAt) {
    return `${agoText(lastSync.failedAt)}`;
  }

  return `${agoText(lastSync.createdAt)}`;
}

function agoText(timestamp: string) {
  const date = new Date(timestamp);

  if (differenceInMinutes(new Date(), date) < 2) {
    return "Just now";
  }

  return formatDistanceToNowStrict(date, { addSuffix: true });
}
