import { Link } from "client/components/Link";
import { MarketingShell } from "client/components/MarketingShell";
import { format } from "date-fns";

type Props = {
  posts: Post[];
};

type Post = {
  title: string;
  path: string;
  description: string;
  publishedAt: Date;
};

export function BlogIndexPage(props: Props) {
  const { posts } = props;

  return (
    <MarketingShell>
      <h1 className="text-4xl font-bold">Blog Posts</h1>
      <ol>
        {posts
          .sort()
          .reverse()
          .map((post) => (
            <li key={post.path} className="mt-8">
              <Link className="text-xl font-bold link-blend" href={post.path}>
                {post.title}
              </Link>
              <div className="text-sm text-grey">
                {format(post.publishedAt, "MMMM do, yyyy")}
              </div>
              <div className="mt-2">{post.description}</div>
            </li>
          ))}
      </ol>
    </MarketingShell>
  );
}
