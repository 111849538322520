import { BlogPostShell } from "client/components/BlogPostShell";
import { ExternalLink } from "client/components/Link";
import { NextPage } from "client/components/NextPage";
import { LinkProps } from "universal/types/app";
import { blogMetadataToSeo } from "universal/utils/blog";

export const meta = {
  title: "Monthly Plan Price Increase",
  description:
    "The cost of the Vicariously monthly plan is increasing from $2 to $5. Existing subscriptions will not be affected.",
  path: "/blog/monthly-plan-price-increase",
  publishedAt: new Date("2021-04-26T19:10:46.991Z"),
};

export function linkProps(): LinkProps {
  return { href: meta.path };
}

export default function Page() {
  return (
    <NextPage
      seo={blogMetadataToSeo(meta)}
      PageImpl={BlogPage}
      pageProps={{}}
    />
  );
}

function BlogPage() {
  return (
    <BlogPostShell meta={meta}>
      <p>
        Starting today, the cost of the Vicariously monthly plan will increase
        from $2 to $5. This change will only affect new customers. Existing
        users on the $2/month plan will be grandfathered in and continue to pay
        that rate indefinitely.
      </p>

      <p>
        The primary motivation behind this change is to reduce the percentage of
        revenue going towards payment processing fees. Vicariously uses{" "}
        <ExternalLink className="link" href="https://stripe.com/">
          Stripe
        </ExternalLink>{" "}
        for payments and subscriptions. Stripe is a fantastic platform, however,
        its pricing model is not advantageous to small charges.
      </p>

      <p>
        For each charge Vicariously makes, Stripe takes 3.4% + 30¢ in processing
        fees. For a charge totaling $2, Vicariously is paying 37¢ in processing
        fees resolving to an effective rate of 18.4%. That's tough to swallow.
        At $5, that rate gets reduced to 9.4%. That's still relatively high, but
        hey, at least it's under 10% – the arbitrary threshold set when deciding
        to make this change.
      </p>

      <p>
        It's also important to note that most Vicariously customers opt for the
        yearly plan. It's affordable at $12/year and is the more sensible plan
        in most situations. In fact, the monthly plan only exists because I
        thought it made the subscription CTA more visually appealing. 🤫
      </p>
    </BlogPostShell>
  );
}
