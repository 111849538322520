import NextLink from "next/link";
import React from "react";

type Props = React.ComponentProps<typeof NextLink> & {
  className?: string;
  children: React.ReactNode;
};

export function Link(props: Props) {
  const { className, ...rest } = props;

  const classes = `${className || ""} focus:outline-none`;

  return <NextLink {...rest} className={classes} />;
}
