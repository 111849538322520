import { Avatar } from "client/components/Avatar";
import { BrandLogoLink } from "client/components/BrandLogoLink";
import { ButtonBase } from "client/components/Button";
import { Container } from "client/components/Container";
import { Footer } from "client/components/Footer";
import { MenuLine } from "client/components/icons";
import { ExternalLink } from "client/components/Link";
import { useAuth } from "client/lib/hooks/useAuth";
import { useRouter } from "next/router";
import { linkProps as appLinkProps } from "pages/app";
import { linkProps as errorLinkProps } from "pages/error";
import { linkProps as logInLinkProps } from "pages/log-in";
import React, { useEffect, useState } from "react";
import { MenuDialog } from "./MenuDialog";

type Props = {
  children: React.ReactNode;
};

export function AppShell(props: Props) {
  const { children } = props;

  const router = useRouter();

  const auth = useAuth();
  const user = auth.data?.user;

  const [showMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    if (auth.status === "error") {
      router.push(errorLinkProps().href);
    }

    if (auth.status === "loaded" && auth.data.user === null) {
      router.push(logInLinkProps(router.asPath).href);
    }
  }, [auth]);

  return (
    <div className="min-h-screen h-full bg-cultured flex flex-col">
      <div className="flex-none">
        <div className="border-b-2 border-davys">
          <Container>
            <div className="py-2 flex justify-between items-center">
              {renderAvatarMaybe()}
              <BrandLogoLink href={appLinkProps().href} />
              {renderMenuMaybe()}
            </div>
          </Container>
        </div>
      </div>

      <div className="flex-1">
        <Container>
          <div className="py-12">{children}</div>
        </Container>
      </div>

      <div className="flex-none">
        <Footer />
      </div>

      {showMenu && user && (
        <MenuDialog user={user} onClose={() => setShowMenu(false)} />
      )}
    </div>
  );

  function renderAvatarMaybe() {
    if (!user) return <div />;

    return (
      <ExternalLink className="link-keyboard" href={user.twitter.profileUrl}>
        <Avatar user={user.twitter} size={40} appearance="cultured" />
      </ExternalLink>
    );
  }

  function renderMenuMaybe() {
    if (!user) return <div />;

    return (
      <ButtonBase
        className="flex items-center link-keyboard"
        onClick={() => setShowMenu(true)}
      >
        <MenuLine className="w-10 h-10 fill-current" />
      </ButtonBase>
    );
  }
}
