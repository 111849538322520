interface Props {
  className: string;
  background: "davys" | "cultured";
}

export function Intersect(props: Props) {
  const { className, background } = props;

  const strokeColor = background === "davys" ? "#faf9f7" : "#50514f";
  const fillColor = background === "davys" ? "#50514f" : "#faf9f7";

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1410 940"
    >
      <g stroke={strokeColor} strokeWidth="75" fill="none" fillRule="evenodd">
        <circle fill={fillColor} cx="940" cy="470" r="432.5" />
        <circle fill={fillColor} cx="470" cy="470" r="432.5" />
        <path
          d="M705 833.158C826.837 754.216 902.5 618.718 902.5 470c0-148.718-75.663-284.216-197.5-363.158C583.163 185.784 507.5 321.282 507.5 470c0 148.718 75.663 284.216 197.5 363.158z"
          fill="#F15BB5"
        />
      </g>
    </svg>
  );
}
