import { AppShell } from "client/components/AppShell";
import { Loader } from "client/components/Loader";
import { useToasts } from "client/components/Toast";
import { useAuth } from "client/lib/hooks/useAuth";
import { updateUser } from "client/lib/mutations/updateUser";
import React from "react";
import { API } from "universal/types/api";
import { SettingsPageBody } from "./SettingsPageBody";

type Props = {};

export function SettingsPage(props: Props) {
  // NOTE (jake): error and unauth branches are being handled up the
  // tree by the AppShell component
  const { data, mutate } = useAuth();
  const { addToast } = useToasts();

  return <AppShell>{renderLoaderOrBody()}</AppShell>;

  function renderLoaderOrBody() {
    if (!data?.user) {
      return <Loader />;
    }

    return (
      <SettingsPageBody
        user={data.user}
        onUpdateNotificationSettings={onUpdateNotificationSettings}
        onUpdatePrivacyControls={onUpdatePrivacyControls}
      />
    );
  }

  async function onUpdateNotificationSettings(
    user: API.UserSelf,
    changes: API.NotificationSettings
  ) {
    try {
      await mutate(
        updateUser(user.id, { notificationSettings: changes }),
        false
      );

      addToast({
        type: "success",
        body: "Your notification settings have been updated",
      });
    } catch (e) {
      addToast({ type: "error", body: e.message });
    }
  }

  async function onUpdatePrivacyControls(
    user: API.UserSelf,
    changes: API.PrivacyControls
  ) {
    try {
      await mutate(updateUser(user.id, { privacyControls: changes }), false);

      addToast({
        type: "success",
        body: "Your privacy controls have been updated",
      });
    } catch (e) {
      addToast({ type: "error", body: e.message });
    }
  }
}
