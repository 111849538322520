import { NextSeo, NextSeoProps } from "next-seo";
import React from "react";

type Props = {
  seo: NextSeoProps;
  PageImpl: React.ComponentType<any>;
  pageProps: any;
};

export function NextPage(props: Props) {
  const { seo, PageImpl, pageProps } = props;

  return (
    <React.Fragment>
      <NextSeo {...seo} />
      <PageImpl {...pageProps} />
    </React.Fragment>
  );
}
