import { Button } from "client/components/Button";
import { Dialog } from "client/components/Dialog";
import { CheckboxField, Form, SubmitButton } from "client/components/form";
import { Awaitable } from "universal/types/utils";

interface Props {
  onCancel: () => void;
  onConfirm: (destroy: boolean) => Awaitable<void>;
}

type FormValues = {
  destroy: boolean;
};

export function UnlinkConfirmationDialog(props: Props) {
  const { onCancel, onConfirm } = props;

  const initialValues = {
    destroy: false,
  };

  return (
    <Dialog onClose={onCancel}>
      <Form onSubmit={onSubmit} initialValues={initialValues}>
        <div className="text-2xl font-bold">Unlink list</div>

        <div className="mt-4">
          If you unlink this list, it will no longer be updated by Vicariously.
        </div>

        <div className="mt-4">
          You can also opt into having this list deleted from Twitter. Keep in
          mind though,{" "}
          <span className="font-bold">this action cannot be reversed.</span>
        </div>

        <div className="mt-6">
          <CheckboxField
            name="destroy"
            border={true}
            label={
              <span className="text-error font-bold">
                Delete Twitter list too?
              </span>
            }
          />
        </div>

        <div className="mt-16 flex justify-end">
          <Button
            color="cultured"
            border={true}
            size="medium"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <div className="ml-4">
            <SubmitButton
              allowPristineSubmit={true}
              color="davys"
              border={false}
              size="medium"
            >
              Unlink
            </SubmitButton>
          </div>
        </div>
      </Form>
    </Dialog>
  );

  async function onSubmit(values: FormValues) {
    await onConfirm(values.destroy);
  }
}
