interface Props {
  className: string;
  background: "davys" | "cultured";
}

export function Subtract(props: Props) {
  const { className, background } = props;

  const strokeColor = background === "davys" ? "#faf9f7" : "#50514f";
  const fillColor = background === "davys" ? "#50514f" : "#faf9f7";

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1410 940"
    >
      <g stroke={strokeColor} strokeWidth="75" fill="none" fillRule="evenodd">
        <circle fill="#F15BB5" cx="470" cy="470" r="432.5" />
        <circle fill={fillColor} cx="940" cy="470" r="432.5" />
      </g>
    </svg>
  );
}
