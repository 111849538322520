import { useField } from "react-final-form";

type Props = {
  name: string;
};

export function HiddenField(props: Props) {
  const { name } = props;

  const { input, meta } = useField(name);

  return <input type="hidden" {...input} />;
}
