import { useScript } from "client/lib/hooks/useScript";
import React, { useEffect, useRef } from "react";

type Props = {
  className?: string;
  children: React.ReactNode;
};

export function EmbeddedTweet(props: Props) {
  const { className, children } = props;

  const ref = useRef(null);

  const scriptStatus = useScript("https://platform.twitter.com/widgets.js");

  useEffect(() => {
    if (!ref.current || scriptStatus !== "ready") return;

    (window as any).twttr.widgets.load(ref.current);
  }, [ref.current, scriptStatus]);

  return (
    <div className={className} ref={ref}>
      {children}
    </div>
  );
}
