import { SyncFrequency } from "@prisma/client";
import { API } from "universal/types/api";

type Data = {
  list: API.List;
};

export async function updateList(
  listId: string,
  syncFrequency: SyncFrequency
): Promise<Data> {
  const res = await fetch("/api/lists", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ listId, syncFrequency }),
  });

  const json = await res.json();

  if (!res.ok) {
    throw new Error(json.error);
  }

  return json;
}
