import { API } from "universal/types/api";
import { useEffect } from "react";
import { Maybe } from "universal/types/utils";

const LIST_REVALIDATE_INTERVAL = 5000;

export function useRevalidateListsWhenSyncing(
  lists: Maybe<API.List[]>,
  revalidateLists: any
) {
  useEffect(() => {
    const isSyncing = (lists || []).some(
      (x) => x.lastSync.status === "IN_PROGRESS"
    );

    if (!isSyncing) return;

    const interval = setInterval(revalidateLists, LIST_REVALIDATE_INTERVAL);

    return () => clearInterval(interval);
  }, [lists]);
}
