import { Dialog } from "client/components/Dialog";
import {
  CustomerServiceLine,
  LogoutBoxLine,
  Settings4Line,
  TwitterLine,
} from "client/components/icons";
import { ExternalLink, Link } from "client/components/Link";
import { linkProps as settingsLinkProps } from "pages/settings";
import { API } from "universal/types/api";

type Props = {
  user: API.UserSelf;
  onClose: () => void;
};

export function MenuDialog(props: Props) {
  const { user, onClose } = props;

  return (
    <Dialog onClose={onClose}>
      <div className="text-2xl font-bold">Hi @{user.twitter.screenName}</div>

      <ul className="mt-4 text-lg">
        <li>
          <OptionButton
            Icon={Settings4Line}
            href={settingsLinkProps().href}
            external={false}
          >
            Account settings
          </OptionButton>
        </li>

        <li>
          <OptionButton
            Icon={TwitterLine}
            href={`${user.twitter.profileUrl}/lists`}
            external={true}
          >
            View lists on Twitter
          </OptionButton>
        </li>

        <li>
          <OptionButton
            Icon={CustomerServiceLine}
            href="https://twitter.com/messages/compose?recipient_id=948493988897554433"
            external={true}
          >
            Have a question?
          </OptionButton>
        </li>

        <li>
          <OptionButton
            Icon={LogoutBoxLine}
            href="/api/auth/log-out"
            external={false}
          >
            Log out
          </OptionButton>
        </li>
      </ul>
    </Dialog>
  );
}

type OptionButtonProps = {
  Icon: any;
  href: string;
  external: boolean;
  children: React.ReactNode;
};

function OptionButton(props: OptionButtonProps) {
  const { Icon, href, external, children } = props;

  const classes = "inline-block py-2 text-davys link-blend";
  const Component = external ? ExternalLink : Link;

  return (
    <Component className={classes} href={href}>
      <div className="flex items-center">
        <Icon className="w-6 h-6 fill-current" />
        <div className="ml-2">{children}</div>
      </div>
    </Component>
  );
}
