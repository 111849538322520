import { Form, SubmitButton } from "client/components/form";
import {
  AlertLine,
  CheckboxCircleLine,
  ErrorWarningLine,
  RefreshLine,
  TwitterLine,
} from "client/components/icons";
import { ExternalLink } from "client/components/Link";
import { useToasts } from "client/components/Toast";
import { useFollowingStatus } from "client/lib/hooks/useFollowingStatus";
import { followBrand } from "client/lib/mutations/followBrand";
import React from "react";
import { API } from "universal/types/api";

type Props = {
  user: API.UserSelf;
};

export function FollowPrompt(props: Props) {
  const { user } = props;

  const { data, error, mutate } = useFollowingStatus(user.id, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const { addToast } = useToasts();

  return (
    <div>
      <div className="w-full h-[2px] bg-cultured rounded-full" />
      {renderBody()}
    </div>
  );

  function renderBody() {
    const rootClasses = "flex mt-4";
    const iconClasses = "flex-none w-6 h-6 fill-current";

    if (error) {
      return (
        <div className={rootClasses}>
          <ErrorWarningLine className={`text-error ${iconClasses}`} />
          <div className="ml-2">
            Unable to figure out if you're following{" "}
            <ExternalLink
              className="link"
              href="https://twitter.com/getvicarious"
            >
              @getvicarious
            </ExternalLink>
            . This is checked to see if it's possible to direct message you.
          </div>
        </div>
      );
    }

    if (data?.following === true) {
      return (
        <div className={rootClasses}>
          <CheckboxCircleLine className={`text-success ${iconClasses}`} />
          <div className="ml-2">
            Thanks so much for following{" "}
            <ExternalLink
              className="link"
              href="https://twitter.com/getvicarious"
            >
              @getvicarious
            </ExternalLink>
            ! This makes it possible to direct message you according to your
            above notification settings.
          </div>
        </div>
      );
    }

    if (data?.following === false) {
      return (
        <div>
          <div className={rootClasses}>
            <AlertLine className={`text-warning ${iconClasses}`} />
            <div className="ml-2">
              Looks like you're not following{" "}
              <ExternalLink
                className="link"
                href="https://twitter.com/getvicarious"
              >
                @getvicarious
              </ExternalLink>{" "}
              yet. That's ok, but unless you've configure your account to{" "}
              <ExternalLink
                className="link"
                href="https://help.twitter.com/en/using-twitter/direct-message-faqs"
              >
                receive direct messages from anyone
              </ExternalLink>
              , Vicariously cannot direct message you.
            </div>
          </div>

          <Form className="flex justify-center mt-8" onSubmit={onFollowBrand}>
            <SubmitButton
              color="cultured"
              border={false}
              size="medium"
              allowPristineSubmit={true}
            >
              <div className="flex items-center">
                <TwitterLine className="flex-none w-6 h-6 fill-current" />
                <div className="ml-2">Follow @getvicarious</div>
              </div>
            </SubmitButton>
          </Form>
        </div>
      );
    }

    return (
      <div className={rootClasses}>
        <RefreshLine className={`text-pink spin ${iconClasses}`} />
        <div className="ml-2">
          Checking follow status of{" "}
          <ExternalLink
            className="link"
            href="https://twitter.com/getvicarious"
          >
            @getvicarious
          </ExternalLink>
          ...
        </div>
      </div>
    );
  }

  async function onFollowBrand() {
    try {
      await mutate(() => followBrand(user.id), false);

      addToast({
        type: "success",
        body: "Thanks for the follow!",
      });
    } catch (e) {
      addToast({ type: "error", body: e.message });
    }
  }
}
