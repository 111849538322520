import { MouseEvent } from "react";

type AnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;

type Props = Omit<AnchorProps, "target" | "rel"> & {
  noReferrer?: boolean;
};

export function ExternalLink(props: Props) {
  const { noReferrer, ...rest } = props;

  const className = `${rest.className || ""} focus:outline-none`;
  const rel = noReferrer ? "noreferrer" : "noopener";

  return <a {...rest} className={className} target="_blank" rel={rel} />;
}

ExternalLink.defaultProps = {
  noReferrer: false,
};
