import { Card } from "client/components/Card";
import { MarketingShell } from "client/components/MarketingShell";
import {
  Classic,
  Difference,
  Intersect,
  Subtract,
  Union,
} from "client/components/svgs";
import { CardSample } from "./CardSample";

interface Props {
  samples: CardSample.Sample[];
}

export function IndexPage(props: Props) {
  const { samples } = props;

  return (
    <MarketingShell>
      <h1 className="inline bg-davys text-xl text-cultured font-bold leading-tight sm:text-2xl md:text-4xl">
        <span className="px-2">Get out of your Twitter bubble</span>
        <br />
        <span className="px-2">and into someone else's</span>
      </h1>

      <div className="mt-8 sm:mt-16 sm:text-lg">
        Vicariously offers the easiest way to{" "}
        <span className="font-bold">
          create Twitter lists based on the follows of other users
        </span>
        . In addition, Vicariously{" "}
        <span className="font-bold">syncs lists regularly</span> to ensure
        they're always up to date.
      </div>

      <div className="mt-8 sm:mt-12">
        <Card color="cultured" padding="6">
          <div className="text-xl text-center font-bold sm:text-2xl">
            List types available on Vicariously
          </div>

          <div className="mt-8 flex flex-col justify-center items-center sm:flex-row">
            <div className="flex">
              <div className="flex-1">
                <Classic className="h-10 mx-auto" background="cultured" />
                <div className="mt-2 text-center font-bold">Classic</div>
              </div>

              <div className="flex-1 ml-8">
                <Union className="h-10 mx-auto" background="cultured" />
                <div className="mt-2 text-center font-bold">Union</div>
              </div>

              <div className="flex-1 ml-8">
                <Intersect className="h-10 mx-auto" background="cultured" />
                <div className="mt-2 text-center font-bold">Intersect</div>
              </div>
            </div>

            <div className="mt-8 flex sm:mt-0 sm:ml-8">
              <div className="flex-1">
                <Subtract className="h-10 mx-auto" background="cultured" />
                <div className="mt-2 text-center font-bold">Subtract</div>
              </div>

              <div className="flex-1 ml-8">
                <Difference className="h-10 mx-auto" background="cultured" />
                <div className="mt-2 text-center font-bold">Difference</div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className="mt-8 sm:mt-12">
        <Card color="davys" padding="6">
          <div className="text-center font-bold sm:text-xl">
            Vicariously is shutting down
          </div>

          <div className="mt-4 text-md text-center">
            Due to changes with the Twitter API, Vicariously is no longer able
            to offer its services in a reliable manner. Payments for active
            subscriptions were stopped in January 2023. Thank you to all who
            supported Vicariously!
          </div>
        </Card>
      </div>
    </MarketingShell>
  );
}
