import { Button } from "client/components/Button";
import React from "react";
import { Maybe } from "universal/types/utils";

type Props = Omit<React.ComponentProps<typeof Button>, "type"> & {
  redirectTo?: Maybe<string>;
};

export function LogInButton(props: Props) {
  const { redirectTo, ...rest } = props;

  const query = redirectTo
    ? `?redirectTo=${encodeURIComponent(redirectTo)}`
    : "";
  const action = `/api/auth/login${query}`;

  return (
    <form action={action} method="post">
      <Button {...rest} type="submit" />
    </form>
  );
}
