import { ButtonBase } from "client/components/Button";
import {
  AlertLine,
  CheckboxCircleLine,
  ErrorWarningLine,
  RefreshLine,
  SkullLine,
} from "client/components/icons";
import { API } from "universal/types/api";
import React, { useState } from "react";
import { SyncDetailsDialog } from "./SyncDetailsDialog";
import { SyncStatusText } from "./SyncStatusText";
import { isAbandonedSyncState } from "./utils";

interface Props {
  list: API.List;
}

export function SyncStatus(props: Props) {
  const { list } = props;

  const [showSyncDetails, setShowSyncDetails] = useState<boolean>(false);

  return (
    <React.Fragment>
      <ButtonBase
        className="text-sm text-alabaster flex items-center focus-visible:underline"
        title={getTitle(list)}
        onClick={showSyncDetailsDialog}
      >
        {renderIcon(list)}
        <div className="ml-1">
          <SyncStatusText list={list} />
        </div>
      </ButtonBase>

      {showSyncDetails && (
        <SyncDetailsDialog
          list={list}
          syncFrequency={list.syncFrequency}
          onClose={hideDialogs}
        />
      )}
    </React.Fragment>
  );

  function renderIcon(list: API.List) {
    const { lastSync } = list;
    const baseClassName = "w-5 h-5 fill-current";

    if (isAbandonedSyncState(list)) {
      return <SkullLine className={`text-error ${baseClassName}`} />;
    }

    switch (lastSync?.status) {
      case "SUCCEEDED":
        return (
          <CheckboxCircleLine className={`text-success ${baseClassName}`} />
        );

      case "AT_CAPACITY":
      case "THROTTLED":
        return <AlertLine className={`text-warning ${baseClassName}`} />;

      case "FAILED":
        return <ErrorWarningLine className={`text-error ${baseClassName}`} />;

      case "IN_PROGRESS":
      default:
        return <RefreshLine className={`text-pink spin ${baseClassName}`} />;
    }
  }

  function getTitle(list: API.List) {
    const { lastSync } = list;

    if (isAbandonedSyncState(list)) {
      return "We've given up";
    }

    switch (lastSync?.status) {
      case "SUCCEEDED":
        return "All systems go";

      case "AT_CAPACITY":
        return "Max list size reached";

      case "THROTTLED":
        return "You may be getting throttled";

      case "FAILED":
        return "Experiencing technical difficulties";

      case "IN_PROGRESS":
      default:
        return "Sync in progress";
    }
  }

  function showSyncDetailsDialog() {
    setShowSyncDetails(true);
  }

  function hideDialogs() {
    setShowSyncDetails(false);
  }
}
