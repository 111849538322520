import { CheckboxField, Form, SubmitButton } from "client/components/form";
import { API } from "universal/types/api";
import { Awaitable } from "universal/types/utils";
import { SectionCard } from "./SectionCard";

interface Props {
  privacyControls: API.PrivacyControls;
  onSubmit: (values: API.PrivacyControls) => Awaitable<void>;
}

type FormValues = {
  list: boolean;
  member: boolean;
};

export function PrivacyControlsCard(props: Props) {
  const { privacyControls, onSubmit } = props;

  const initialValues = {
    list: !!privacyControls.list,
    member: !!privacyControls.member,
  };

  return (
    <SectionCard title="Privacy Settings">
      <Form initialValues={initialValues} onSubmit={onSubmitProxy}>
        <div className="mt-8">
          <CheckboxField
            name="member"
            border={false}
            label="Prevent users from adding me to Vicariously generated lists"
          />
        </div>

        <div className="mt-4">
          <CheckboxField
            name="list"
            border={false}
            label="Prevent users from creating Vicariously lists based on my follows"
          />
        </div>

        <div className="mt-8">
          <SubmitButton color="cultured" border={false} size="medium">
            Update privacy settings
          </SubmitButton>
        </div>
      </Form>
    </SectionCard>
  );

  async function onSubmitProxy(values: FormValues) {
    await onSubmit(values);
  }
}
