import { BrandLogoLink } from "client/components/BrandLogoLink";
import { Container } from "client/components/Container";
import { linkProps as indexLinkProps } from "pages/index";

type Props = {};

export function Header(props: Props) {
  return (
    <div className="border-b-2 border-davys">
      <Container>
        <div className="py-2 flex justify-between items-center">
          <BrandLogoLink href={indexLinkProps().href} />
        </div>
      </Container>
    </div>
  );
}
