import { NextPage } from "client/components/NextPage";
import { FaqPage, SectionDeepLink } from "client/pages/FaqPage";
import React from "react";
import { LinkProps } from "universal/types/app";
import { brandTitle } from "universal/utils/seo";
import { toAbsoluteUrl } from "universal/utils/url";

export function linkProps(deepLink?: SectionDeepLink): LinkProps {
  const suffix = deepLink ? `#${deepLink}` : "";

  return { href: `/faq${suffix}` };
}

const TITLE = brandTitle("Frequently Asked Questions");
const DESCRIPTION =
  "The most commonly asked questions about Vicariously mixed in with some not so common ones.";

export default function Page() {
  const canonical = toAbsoluteUrl(linkProps());

  return (
    <NextPage
      seo={{ title: TITLE, description: DESCRIPTION, canonical }}
      PageImpl={FaqPage}
      pageProps={{}}
    />
  );
}
