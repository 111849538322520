import { AlertLine, ErrorWarningLine } from "client/components/icons";
import React from "react";

type Props = {
  type: AlertType;
  size: Size;
  children: React.ReactNode;
};

type AlertType = "warning" | "error";
type Size = "sm" | "md";

export function AlertBox(props: Props) {
  const { type, size, children } = props;

  const Icon = getIcon(type);
  const className = ["flex items-start", getColorClass(type)].join(" ");
  const iconClassName = ["flex-none fill-current", getIconSizeClass(size)].join(
    " "
  );
  const childrenClassName = ["ml-2", getTextSizeClass(size)].join(" ");

  return (
    <div className={className}>
      <Icon className={iconClassName} />
      <div className={childrenClassName}>{children}</div>
    </div>
  );
}

function getIcon(type: AlertType) {
  switch (type) {
    case "warning":
      return AlertLine;

    case "error":
      return ErrorWarningLine;
  }
}

function getColorClass(type: AlertType) {
  switch (type) {
    case "warning":
      return "text-warning";

    case "error":
      return "text-error";
  }
}

function getTextSizeClass(size: Size) {
  switch (size) {
    case "sm":
      return "text-sm";

    case "md":
      return "text-base";
  }
}

function getIconSizeClass(size: Size) {
  switch (size) {
    case "sm":
      return "w-5 h-5";

    case "md":
      return "w-6 h-6";
  }
}
