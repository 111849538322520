import { NextPage } from "client/components/NextPage";
import { ErrorPage } from "client/pages/ErrorPage";
import React from "react";
import { LinkProps } from "universal/types/app";
import { brandTitle } from "universal/utils/seo";

export function linkProps(): LinkProps {
  return { href: "/error" };
}

const TITLE = brandTitle("Sorry, that shouldn't happen");

export default function Page() {
  return (
    <NextPage
      seo={{ title: TITLE, noindex: true }}
      PageImpl={ErrorPage}
      pageProps={{}}
    />
  );
}
