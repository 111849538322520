import { Form, SubmitButton } from "client/components/form";
import { createBillingPortalSession } from "client/lib/mutations/createBillingPortalSession";
import { useRouter } from "next/router";
import React from "react";
import { SectionCard } from "./SectionCard";

type Props = {};

export function BillingCard(props: Props) {
  const router = useRouter();

  return (
    <SectionCard title="Billing">
      <p>
        Vicariously uses Stripe to manage billing. Head to the Stripe billing
        portal to manage your subscription, update your payment method, or view
        invoice history.
      </p>

      <Form className="mt-8 flex justify-center" onSubmit={onRedirectToStripe}>
        <SubmitButton
          color="cultured"
          border={false}
          size="medium"
          allowPristineSubmit={true}
        >
          Head to billing portal
        </SubmitButton>
      </Form>
    </SectionCard>
  );

  async function onRedirectToStripe() {
    const session = await createBillingPortalSession();

    await router.push(session.url);
  }
}
