import { EmbeddedTweet } from "client/components/EmbeddedTweet";
import { ExternalLink, Link } from "client/components/Link";
import { ResourceShell } from "client/components/ResourceShell";
import { linkProps as settingsLinkProps } from "pages/settings";
import React from "react";

export type SectionDeepLink =
  | "contact"
  | "list-types"
  | "name-description"
  | "permissions"
  | "opt-out"
  | "discrepancy"
  | "throttled"
  | "abandoned"
  | "subscription"
  | "logo"
  | "who";

type Props = {};

export function FaqPage(props: Props) {
  return (
    <ResourceShell>
      <h1>Frequently Asked Questions</h1>

      <dl>
        <dt>
          <h2 id="contact">How do I contact Vicariously for support?</h2>
        </dt>

        <dd>
          <p>
            Reach out on Twitter! A{" "}
            <ExternalLink
              className="link"
              href="https://twitter.com/intent/tweet?text=@getvicarious"
            >
              simple tweet
            </ExternalLink>{" "}
            should get the job done or if you want something more private,{" "}
            <ExternalLink
              className="link"
              href="https://twitter.com/messages/compose?recipient_id=948493988897554433"
            >
              DMs are open
            </ExternalLink>
            .
          </p>
        </dd>

        <dt>
          <h2 id="list-types">
            Can you explain the differences between the different list types?
          </h2>
        </dt>

        <dd>
          <p>
            Vicariously offers five different list types. They're all more or
            less inspired by{" "}
            <ExternalLink
              className="link"
              href="https://en.wikipedia.org/wiki/Set_theory"
            >
              set theory
            </ExternalLink>
            .
          </p>

          <p>
            The <strong>classic</strong> list is the simplest and was the
            original list type on Vicariously (hence the name). It takes all the
            follows of a given user and adds them to a list. Simple.
          </p>

          <p>
            The <strong>union</strong> list is similar to a classic list,
            however it works with multiple users. It takes all the follows of a
            set of given users and adds them to a list.
          </p>

          <p>
            The <strong>intersect</strong> list takes the mutual follows of a
            set of given users and adds them to a list.
          </p>

          <p>
            The <strong>subtract</strong> list starts with the follows of a
            given base user and then removes all the follows of a set of other
            users. With this list type, the order you enter the @handles in
            matters as the first @handle will be the base user.
          </p>

          <p>
            The <strong>difference</strong> list adds the exclusive follows of a
            set of given users to a list.
          </p>
        </dd>

        <dt>
          <h2 id="name-description">
            Will changing the name and/or description of lists break anything?
          </h2>
        </dt>

        <dd>
          <p>
            Nope! Vicariously references lists by their immutable ID so changing
            the name and/or description of lists will not affect Vicariously
            list syncs.
          </p>
        </dd>

        <dt>
          <h2 id="permissions">
            Why do you need so many permissions from my Twitter account?
          </h2>
        </dt>

        <dd>
          <p>
            Unfortunately, the{" "}
            <ExternalLink href="https://developer.twitter.com/en/docs/apps/app-permissions">
              Twitter API only offers three levels of permissions
            </ExternalLink>
            :
          </p>

          <ul>
            <li>Read only</li>
            <li>Read and write</li>
            <li>Read, write, and access direct messages</li>
          </ul>

          <p>
            Vicariously uses "Read and write" since it needs to create lists on
            your behalf.
          </p>
        </dd>

        <dt>
          <h2 id="opt-out">
            Can I prevent Vicariously from adding me to lists and creating lists
            based on my follows?
          </h2>
        </dt>

        <dd>
          <p>
            You sure can. There's a handful of mechanisms you can use to achieve
            this.
          </p>

          <p>
            First, if your{" "}
            <ExternalLink href="https://help.twitter.com/en/safety-and-security/public-and-protected-tweets">
              Twitter account is protected
            </ExternalLink>
            , the only Twitter users who will be able to access your follows and
            tweets are users who you allow to follow you.
          </p>

          <p>
            Second,{" "}
            <ExternalLink href="https://help.twitter.com/en/using-twitter/blocking-and-unblocking-accounts">
              users who you block
            </ExternalLink>{" "}
            will also not be able to access your follows and tweets.
          </p>

          <p>
            Finally, Vicariously allows you to opt-out through privacy controls
            available on the{" "}
            <Link className="link" href={settingsLinkProps().href}>
              account settings page
            </Link>
            .
          </p>

          <EmbeddedTweet className="flex my-6 justify-center">
            <blockquote className="twitter-tweet" data-conversation="none">
              <p lang="en" dir="ltr">
                To access these controls, you need to log into{" "}
                <a href="https://t.co/u9uNO1XoyD">https://t.co/u9uNO1XoyD</a>{" "}
                and then it should be front and center.{" "}
                <a href="https://t.co/fXTl4JqYlm">pic.twitter.com/fXTl4JqYlm</a>
              </p>
              &mdash; Vicariously (@getvicarious){" "}
              <a href="https://twitter.com/getvicarious/status/1287940953504792577?ref_src=twsrc%5Etfw">
                July 28, 2020
              </a>
            </blockquote>
          </EmbeddedTweet>

          <EmbeddedTweet className="flex my-6 justify-center">
            <blockquote className="twitter-tweet" data-conversation="none">
              <p lang="en" dir="ltr">
                If you have concerns over giving Vicariously permissions,
                that&#39;s perfectly reasonable! You have 2 choices here:
                <br />
                <br />
                1) Log in, update your privacy controls, revoke permissions from{" "}
                <a href="https://t.co/A2W4xqD0nP">https://t.co/A2W4xqD0nP</a>
                <br />
                <br />
                2) DM this account asking to be added to the denylist
              </p>
              &mdash; Vicariously (@getvicarious){" "}
              <a href="https://twitter.com/getvicarious/status/1287940954599481344?ref_src=twsrc%5Etfw">
                July 28, 2020
              </a>
            </blockquote>
          </EmbeddedTweet>
        </dd>

        <dt>
          <h2 id="discrepancy">
            Why is there a discrepancy between the follow count and list member
            count?
          </h2>
        </dt>

        <dd>
          <p>There are a few things that could be going on.</p>

          <p>
            First, it could just be that the users the list is based on have
            followed and/or unfollowed others recently and Vicariously hasn't
            performed a sync since then. Waiting for the next scheduled sync or
            forcing a sync manually both should resolve this scenario.
          </p>

          <p>
            Second, the user may have made their follows list inaccessible to
            your account. This could have happened if the user blocked you,
            switched to being a protected account, or if they opted out of
            allowing others to access their follows through Vicariously.
          </p>

          <p>
            Third, the Twitter API has an undocumented limitation to how many
            members you can add to lists in a given day. From what I can tell,
            this threshold appears to be ~3,000. You might hit this issue if you
            create a bunch of lists in succession.
          </p>

          <p>
            Vicariously does everything it can to keep your lists up to date and
            as accurate as possible. If things seem out of whack, there's
            probably a valid reason for it. With that said, feel free to reach
            out if you have questions about your lists.
          </p>
        </dd>

        <dt>
          <h2 id="throttled">
            My list status says the Twitter API is throttling me, what does that
            mean?
          </h2>
        </dt>

        <dd>
          <p>
            You've hit the undocumented Twitter API threshold for adding users
            to lists. Since it's undocumented, it's not clear what the rules
            are. If you've just hit this issue with a newly created list, the
            list will usually sync as expected during the next automated
            attempt.
          </p>

          <p>
            It should also be noted that the Twitter API doesn't return an error
            when this happens. Vicariously detects it by comparing the number of
            users expected to be added to a list to the number of users actually
            added to the list. If the delta between those two numbers is high
            enough, Vicariously assumes you're being throttled.
          </p>
        </dd>

        <dt>
          <h2 id="abandoned">My list status says you've given up?!</h2>
        </dt>

        <dd>
          <p>
            Vicariously syncs every list according to its sync frequency. Every
            once in a while a sync will fail. If syncs fail 10 or more
            consecutive times, Vicariously categorizes your list as irredeemable
            and pauses auto syncs.
          </p>

          <p>
            Why not keep trying to sync the list? Well because usually these
            syncs are failing due to external reasons outside the control of
            Vicariously. Here are some examples of when this can happen:
          </p>

          <ul>
            <li>Your Twitter account has been suspended</li>
            <li>Your Twitter account has revoked permissions</li>
            <li>Your list is based on a user who has deleted their account</li>
            <li>
              Your list is based on a user who made their{" "}
              <ExternalLink
                className="link"
                href="https://help.twitter.com/en/safety-and-security/public-and-protected-tweets"
              >
                Tweets protected
              </ExternalLink>{" "}
              and you do not follow them.
            </li>
            <li>
              Your list is based on a user who has blocked your Twitter account
            </li>
          </ul>

          <p>
            If you are able to resolve the underlying issue, you can force sync
            your list to resume auto syncing.
          </p>
        </dd>

        <dt>
          <h2 id="subscription">
            Why does it cost money to create more than 1 list?
          </h2>
        </dt>

        <dd>
          <p className="mt-4">
            It's as simple as this: there are costs to running Vicariously and
            charging a subscription is a way to offset those costs.
          </p>

          <p>
            If you can't afford the subscription but think Vicariously is cool,
            reach out. I'd be more than happy to gift you a free subscription.
          </p>
        </dd>

        <dt>
          <h2 id="logo">What's the logo supposed to be?</h2>
        </dt>

        <dd>
          <p>
            It's a{" "}
            <ExternalLink href="https://en.wikipedia.org/wiki/View-Master">
              View-Master
            </ExternalLink>
            . It felt appropriate considering Vicariously allows you to see into
            another user's timeline.
          </p>
        </dd>

        <dt>
          <h2 id="who">Who created Vicariously?</h2>
        </dt>

        <dd>
          <p>
            That'd be me,{" "}
            <ExternalLink href="https://twitter.com/JakeHarding">
              Jake Harding
            </ExternalLink>
            . When I'm not working my day job at{" "}
            <ExternalLink href="https://officehours.com" noReferrer={false}>
              Office Hours
            </ExternalLink>{" "}
            or hacking away on Vicariously, I'm helping out at{" "}
            <ExternalLink href="https://bohoesthetician.com" noReferrer={false}>
              Boho Esthetician
            </ExternalLink>{" "}
            in Whitefish, MT.
          </p>
        </dd>
      </dl>
    </ResourceShell>
  );
}
