interface Props {
  className: string;
  background: "davys" | "cultured";
}

export function Union(props: Props) {
  const { className, background } = props;

  const strokeColor = background === "davys" ? "#faf9f7" : "#50514f";

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1410 940"
    >
      <path
        d="M686.223 844.662L705 833.8l18.777 10.862C788.957 882.366 862.965 902.5 940 902.5c238.863 0 432.5-193.637 432.5-432.5S1178.863 37.5 940 37.5c-77.035 0-151.044 20.134-216.223 57.838L705 106.2l-18.777-10.862C621.043 57.634 547.035 37.5 470 37.5 231.137 37.5 37.5 231.137 37.5 470S231.137 902.5 470 902.5c77.035 0 151.044-20.134 216.223-57.838z"
        fill="#F15BB5"
        stroke={strokeColor}
        strokeWidth="75"
        fillRule="evenodd"
      />
    </svg>
  );
}
