import useSWR, { SWRConfiguration, SWRResponse } from "swr";
import { Nullable } from "universal/types/utils";

type APIBase<Data, Error> = {
  mutate: SWRResponse<Data, Error>["mutate"];
};

export type APILoading<Data, Error> = APIBase<Data, Error> & {
  status: "loading";
  loading: true;
  error: null;
  data: null;
};

export type APIError<Data, Error> = APIBase<Data, Error> & {
  status: "error";
  loading: false;
  error: any;
  data: null;
};

export type APILoaded<Data, Error> = APIBase<Data, Error> & {
  status: "loaded";
  loading: false;
  error: null;
  data: Data;
};

type API<Data, Error> =
  | APILoading<Data, Error>
  | APIError<Data, Error>
  | APILoaded<Data, Error>;

// TODO (jake): performance
// see https://swr.vercel.app/advanced/performance#dependency-collection
export function useApi<Data, Error = any>(
  path: Nullable<string>,
  options?: SWRConfiguration<Data, Error>
): API<Data, Error> {
  const { data, error, mutate } = useSWR<Data, Error>(path, options);

  if (!data && !error) {
    return {
      status: "loading",
      loading: true,
      error: null,
      data: null,
      mutate,
    };
  }

  // TODO (jake): handle lack of data on client
  if (error || !data) {
    return {
      status: "error",
      loading: false,
      error,
      data: null,
      mutate,
    };
  }

  return {
    status: "loaded",
    loading: false,
    error: null,
    data,
    mutate,
  };
}
