import { SyncFrequency } from "@prisma/client";
import { ButtonBase } from "client/components/Button";
import { Card } from "client/components/Card";
import { LockLine, MoreLine, TwitterLine } from "client/components/icons";
import { ExternalLink } from "client/components/Link";
import {
  Classic,
  Difference,
  Intersect,
  Subtract,
  Union,
} from "client/components/svgs";
import { useState } from "react";
import { API } from "universal/types/api";
import { Awaitable } from "universal/types/utils";
import { ChangeSyncFrequencyDialog } from "./ChangeSyncFrequencyDialog";
import { ListSubject } from "./ListSubject";
import { MoreOptionsDialog } from "./MoreOptionsDialog";
import { SyncStatus } from "./SyncStatus";
import { UnlinkConfirmationDialog } from "./UnlinkConfirmationDialog";

interface Props {
  list: API.List;
  activeSubscription: boolean;
  onChangeSyncFrequency: (
    listId: string,
    syncFrequency: SyncFrequency
  ) => Awaitable<void>;
  onUnlink: (listId: string, destroy: boolean) => Awaitable<void>;
  onForceSync: (listId: string) => Awaitable<void>;
}

type DialogType = "moreOptions" | "unlinkConfirmation" | "changeSyncFrequency";

export function ListCard(props: Props) {
  const {
    list,
    activeSubscription,
    onChangeSyncFrequency,
    onUnlink,
    onForceSync,
  } = props;

  const [shownDialog, setShownDialog] = useState<DialogType | null>(null);

  return (
    <Card color="davys" padding="4">
      <div className="ml-2 float-right">{renderListType()}</div>
      <ol>
        {list.subjects.map((subject, i) => {
          const marginTopClass = i > 0 ? "mt-4" : "";

          return (
            <li key={subject.id} className={marginTopClass}>
              <ListSubject subject={subject} />
            </li>
          );
        })}
      </ol>

      <div className="w-full h-[2px] mt-8 bg-cultured rounded-full" />

      <div className="mt-4 flex justify-between items-center">
        <SyncStatus list={list} />

        <div className="flex items-center">
          {list.private && (
            <div className="text-alabaster" title="Private list">
              <LockLine className="w-5 h-5 fill-current" />
            </div>
          )}
          <ExternalLink
            className="ml-4 text-alabaster link-blend"
            href={list.url}
            title="View list on Twitter"
          >
            <TwitterLine className="w-5 h-5 fill-current" />
          </ExternalLink>

          <ButtonBase
            className="ml-4 text-alabaster link-blend"
            title="See more options"
            onClick={onShowMoreOptions}
          >
            <MoreLine className="w-5 h-5 fill-current" />
          </ButtonBase>
        </div>
      </div>

      {shownDialog === "moreOptions" && (
        <MoreOptionsDialog
          activeSubscription={activeSubscription}
          onCancel={hideDialogs}
          onUnlinkIntent={showUnlinkConfirmationDialog}
          onForceSync={onForceSyncProxy}
          onChangeSyncFrequencyIntent={showChangeSyncFrequencyDialog}
        />
      )}

      {shownDialog === "unlinkConfirmation" && (
        <UnlinkConfirmationDialog
          onCancel={hideDialogs}
          onConfirm={onConfirmUnlink}
        />
      )}

      {shownDialog === "changeSyncFrequency" && (
        <ChangeSyncFrequencyDialog
          list={list}
          onCancel={hideDialogs}
          onChange={onChangeSyncFrequencyProxy}
        />
      )}
    </Card>
  );

  function renderListType() {
    const className = "h-6";

    switch (list.type) {
      case "CLASSIC":
        return (
          <div title="Classic">
            <Classic className={className} background="davys" />
          </div>
        );

      case "UNION":
        return (
          <div title="Union">
            <Union className={className} background="davys" />
          </div>
        );

      case "INTERSECT":
        return (
          <div title="Intersect">
            <Intersect className={className} background="davys" />
          </div>
        );

      case "SUBTRACT":
        return (
          <div title="Subtract">
            <Subtract className={className} background="davys" />
          </div>
        );

      case "DIFFERENCE":
        return (
          <div title="Difference">
            <Difference className={className} background="davys" />
          </div>
        );
    }
  }

  function onShowMoreOptions() {
    showMoreOptionsDialog();
  }

  async function onConfirmUnlink(destroy: boolean) {
    await onUnlink(list.id, destroy);
    // NOTE (jake): no need to call `hideDialogs` as this component will unmount
  }

  async function onForceSyncProxy() {
    await onForceSync(list.id);
    hideDialogs();
  }

  async function onChangeSyncFrequencyProxy(syncFrequency: SyncFrequency) {
    await onChangeSyncFrequency(list.id, syncFrequency);
    hideDialogs();
  }

  function showMoreOptionsDialog() {
    setShownDialog("moreOptions");
  }

  function showUnlinkConfirmationDialog() {
    setShownDialog("unlinkConfirmation");
  }

  function showChangeSyncFrequencyDialog() {
    setShownDialog("changeSyncFrequency");
  }

  function hideDialogs() {
    setShownDialog(null);
  }
}
