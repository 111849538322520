import { TextFieldBase } from "./TextFieldBase";

type Props = Omit<React.ComponentProps<typeof TextFieldBase>, "className"> & {
  fullWidth?: boolean;
};

export function TextField(props: Props) {
  const { fullWidth, ...rest } = props;
  const disabled = !!rest.disabled;

  const classes = [
    fullWidth ? "w-full" : "",
    getColorClasses(disabled),
    "h-12 px-4 rounded-lg placeholder-davys placeholder-opacity-50 focus:outline-none focus:bg-pink focus:text-white focus:placeholder-pink",
  ].join(" ");

  return <TextFieldBase {...rest} className={classes} />;
}

function getColorClasses(disabled: boolean) {
  if (disabled) {
    return "bg-alabaster text-davys";
  }

  return "bg-cultured text-davys";
}
