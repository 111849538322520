import { BlogIndexPage } from "client/pages/BlogIndexPage";
import { NextSeo } from "next-seo";
import React from "react";
import { LinkProps } from "universal/types/app";
import { brandTitle } from "universal/utils/seo";
import { toAbsoluteUrl } from "universal/utils/url";
import { meta as april2021ProductUpdateMeta } from "./april-2021-product-update";
import { meta as january2021ProductUpdateMeta } from "./january-2021-product-update";
import { meta as originsMeta } from "./origins";
import { meta as introducingNewSubjectTypesMeta } from "./introducing-new-subject-types";
import { meta as monthlyPlanPriceIncrease } from "./monthly-plan-price-increase";

const TITLE = brandTitle("Blog");
const DESCRIPTION =
  "The Vicariously blog. Posts cover all things Vicariously, from announcements to usage anecdotes.";

export function linkProps(): LinkProps {
  return { href: "/blog" };
}

export default function BlogIndxPage() {
  const canonical = toAbsoluteUrl(linkProps());

  return (
    <React.Fragment>
      <NextSeo
        title={TITLE}
        description={DESCRIPTION}
        canonical={canonical}
        openGraph={{
          type: "website",
          url: canonical,
          title: TITLE,
          description: DESCRIPTION,
        }}
      />

      <BlogIndexPage
        posts={[
          january2021ProductUpdateMeta,
          originsMeta,
          april2021ProductUpdateMeta,
          monthlyPlanPriceIncrease,
          introducingNewSubjectTypesMeta,
        ]}
      />
    </React.Fragment>
  );
}
