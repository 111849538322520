import { ButtonBase } from "client/components/Button";
import { Dialog } from "client/components/Dialog";
import {
  DeleteBinLine,
  RefreshLine,
  Settings4Line,
} from "client/components/icons";

interface Props {
  activeSubscription: boolean;
  onCancel: () => void;
  onUnlinkIntent: () => void;
  onForceSync: () => void;
  onChangeSyncFrequencyIntent: () => void;
}

export function MoreOptionsDialog(props: Props) {
  const {
    activeSubscription,
    onCancel,
    onChangeSyncFrequencyIntent,
    onUnlinkIntent,
    onForceSync,
  } = props;

  return (
    <Dialog onClose={onCancel}>
      <div className="text-2xl font-bold">More options</div>

      <ul className="mt-4 text-lg">
        <li>
          <OptionButton
            Icon={RefreshLine}
            disabled={!activeSubscription}
            onClick={onForceSync}
          >
            Force sync
          </OptionButton>
        </li>

        <li>
          <OptionButton
            Icon={Settings4Line}
            disabled={!activeSubscription}
            onClick={onChangeSyncFrequencyIntent}
          >
            Change sync frequency…
          </OptionButton>
        </li>

        <li>
          <OptionButton
            Icon={DeleteBinLine}
            disabled={false}
            onClick={onUnlinkIntent}
          >
            Unlink and/or delete list…
          </OptionButton>
        </li>
      </ul>
    </Dialog>
  );
}

type OptionButtonProps = {
  Icon: any;
  disabled: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

function OptionButton(props: OptionButtonProps) {
  const { Icon, disabled, onClick, children } = props;

  const className = [
    "py-2",
    disabled ? "text-grey cursor-not-allowed" : "text-davys link-blend",
  ].join(" ");

  return (
    <ButtonBase className={className} disabled={disabled} onClick={onClick}>
      <div className="flex items-center">
        <Icon className="w-6 h-6 fill-current" />
        <div className="ml-2">{children}</div>
      </div>

      {disabled && (
        <div className="text-sm text-left">Active subscription required</div>
      )}
    </ButtonBase>
  );
}
