import { Avatar } from "client/components/Avatar";
import { FileList2Line } from "client/components/icons";
import { ExternalLink } from "client/components/Link";
import { API } from "universal/types/api";
import {
  getHumanTwitterListContext,
  getHumanTwitterUserContext,
} from "./utils";

type Props = {
  subject: API.ListSubject;
};

export function ListSubject(props: Props) {
  const { subject } = props;

  return subject.type === "list"
    ? renderTwitterList(subject)
    : renderTwitterUser(subject);
}

function renderTwitterUser(subject: API.ListSubjectTwitterUser) {
  const { twitter, context } = subject;

  return (
    <ExternalLink
      className="flex items-center link-blend"
      href={twitter.profileUrl}
    >
      <div className="flex-none">
        <Avatar user={twitter} size={48} appearance="davys" />
      </div>
      <div className="flex-1 ml-2 whitespace-nowrap overflow-hidden">
        <div className="font-bold overflow-hidden overflow-ellipsis md:text-lg">
          @{twitter.screenName}
        </div>
        <div className="text-sm text-alabaster">
          {getHumanTwitterUserContext(context)}
        </div>
      </div>
    </ExternalLink>
  );
}

function renderTwitterList(subject: API.ListSubjectTwitterList) {
  const { twitter, context } = subject;

  return (
    <ExternalLink
      className="flex items-center link-blend"
      href={twitter.permalinkUrl}
    >
      <div className="flex justify-center items-center w-[48px] h-[48px] rounded-md bg-cultured">
        <FileList2Line className="flex-none w-10 h-10 text-davys fill-current" />
      </div>
      <div className="flex-1 ml-2 whitespace-nowrap overflow-hidden">
        <div className="font-bold overflow-hidden overflow-ellipsis md:text-lg">
          {twitter.fullName}
        </div>
        <div className="text-sm text-alabaster">
          {getHumanTwitterListContext(context)}
        </div>
      </div>
    </ExternalLink>
  );
}
