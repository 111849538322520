interface Props {
  className: string;
  background: "davys" | "cultured";
}

export function Classic(props: Props) {
  const { className, background } = props;

  const strokeColor = background === "davys" ? "#faf9f7" : "#50514f";

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 940 940"
    >
      <circle
        cx="470"
        cy="705"
        r="432.5"
        transform="translate(0 -235)"
        fill="#F15BB5"
        stroke={strokeColor}
        strokeWidth="75"
        fillRule="evenodd"
      />
    </svg>
  );
}
