import { useField } from "react-final-form";
import { CheckboxField as CheckboxFieldControl } from "client/components/controls";

type Props = Omit<
  React.ComponentProps<typeof CheckboxFieldControl>,
  "checked"
> & {
  name: string;
};

export function CheckboxField(props: Props) {
  const { name, disabled: disabledProp } = props;

  const { input, meta } = useField(name, {
    type: "checkbox",
    value: props.value,
  });
  const disabled = meta.submitting || disabledProp;

  return <CheckboxFieldControl disabled={disabled} {...props} {...input} />;
}
