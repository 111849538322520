import { Form as RRForm } from "react-final-form";

type Props = React.ComponentProps<typeof RRForm> & {
  className?: string;
};

export function Form(props: Props) {
  const { className, children, ...rest } = props;

  return (
    <RRForm {...rest}>
      {({ handleSubmit }) => {
        return (
          <form className={className} onSubmit={handleSubmit}>
            {children}
          </form>
        );
      }}
    </RRForm>
  );
}
