import { Card } from "client/components/Card";

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
}

export function SectionCard(props: Props) {
  const { title, children } = props;

  return (
    <Card color="davys" padding="6">
      <div className="text-xl text-white font-bold sm:text-2xl md:text-4xl">
        {title}
      </div>

      <div className="mt-8">{children}</div>
    </Card>
  );
}
