interface Props {
  className: string;
}

export function Viewmaster(props: Props) {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 501 365"
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M366.36 12.5c-36.495 13.156-75.595 20-115.727 20-40.131 0-79.231-6.844-115.726-20H68.684a2.5 2.5 0 00-2.462 2.064c-5.333 30.131-9.164 60.257-11.492 90.38l-.701 9.068-8.843 2.124a937.414 937.414 0 00-16.084 4.014 12.5 12.5 0 00-8.79 8.346C15.52 143.715 13.134 158.87 13.134 174c0 15.13 2.387 30.285 7.18 45.504a12.5 12.5 0 008.789 8.346 937.47 937.47 0 0015.931 3.978l8.861 2.13.683 9.088c2.356 31.379 6.342 62.761 11.958 94.148a2.5 2.5 0 002.1 2.034c60.672 8.848 121.336 13.272 181.998 13.272 60.662 0 121.327-4.424 181.999-13.272a2.5 2.5 0 002.1-2.034c5.616-31.387 9.602-62.77 11.958-94.148l.682-9.089 8.862-2.13a937.47 937.47 0 0015.93-3.977 12.5 12.5 0 008.79-8.346c4.793-15.219 7.18-30.374 7.18-45.504 0-15.13-2.387-30.285-7.18-45.504a12.5 12.5 0 00-8.79-8.346 937.406 937.406 0 00-16.083-4.014l-8.843-2.124-.701-9.067c-2.329-30.124-6.16-60.25-11.492-90.38a2.5 2.5 0 00-2.462-2.065H366.36z"
          stroke="#50514F"
          strokeWidth="25"
          fill="#F15BB5"
        />
        <g fill="#50514F">
          <path d="M107.794 124.935c47.613-6.623 95.226-9.935 142.84-9.935 47.612 0 95.225 3.312 142.838 9.935a13.846 13.846 0 0111.563 10.509c3.732 15.685 5.598 31.37 5.598 47.056 0 15.685-1.866 31.37-5.598 47.056a13.846 13.846 0 01-11.563 10.51C345.86 246.687 298.246 250 250.633 250s-95.226-3.312-142.839-9.935a13.846 13.846 0 01-11.562-10.509c-3.732-15.685-5.599-31.37-5.599-47.056 0-15.685 1.867-31.37 5.599-47.056a13.846 13.846 0 0111.562-10.51z" />
          <path d="M121.074 138.14c43.187-5.042 86.373-7.563 129.56-7.563 43.186 0 86.372 2.52 129.558 7.563a13.846 13.846 0 0111.775 10.19c3.032 11.39 4.549 22.78 4.549 34.17s-1.517 22.78-4.55 34.17a13.846 13.846 0 01-11.774 10.19c-43.186 5.042-86.372 7.563-129.559 7.563-43.186 0-86.372-2.52-129.559-7.563a13.846 13.846 0 01-11.774-10.19c-3.033-11.39-4.549-22.78-4.549-34.17s1.516-22.78 4.549-34.17a13.846 13.846 0 0111.774-10.19z" />
        </g>
      </g>
    </svg>
  );
}
