import { NextSeoProps } from "next-seo";
import { BlogMetadata } from "universal/types/app";
import { brandTitle } from "./seo";
import { toAbsoluteUrl } from "./url";

export function blogMetadataToSeo(metadata: BlogMetadata): NextSeoProps {
  const title = brandTitle(metadata.title);
  const canonical = toAbsoluteUrl(metadata.path);

  return {
    title,
    description: metadata.description,
    canonical,
    openGraph: {
      title,
      description: metadata.description,
      url: canonical,
      type: "article",
      article: {
        publishedTime: metadata.publishedAt.toISOString(),
      },
    },
  };
}
