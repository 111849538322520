import { BlogPostImage } from "client/components/BlogPostImage";
import { BlogPostShell } from "client/components/BlogPostShell";
import { ExternalLink, Link } from "client/components/Link";
import { linkProps as settingsLinkProps } from "pages/settings";
import { NextPage } from "client/components/NextPage";
import { LinkProps } from "universal/types/app";
import { blogMetadataToSeo } from "universal/utils/blog";
import { EmbeddedTweet } from "client/components/EmbeddedTweet";

export const meta = {
  title: "April 2021 Product Update",
  description: "See what's new with Vicariously in the month of April 2021.",
  path: "/blog/april-2021-product-update",
  publishedAt: new Date("2021-04-22T19:19:04.341Z"),
};

export function linkProps(): LinkProps {
  return { href: meta.path };
}

export default function Page() {
  return (
    <NextPage
      seo={blogMetadataToSeo(meta)}
      PageImpl={BlogPage}
      pageProps={{}}
    />
  );
}

function BlogPage() {
  return (
    <BlogPostShell meta={meta}>
      <p>
        Howdy! Hope y'all are excited to learn about what's new with Vicariously
        in April 2021:
      </p>

      <ul>
        <li>
          <strong>Direct Message Notifications</strong>: Provides a way to
          notify users about changes to their lists.
        </li>
        <li>
          <strong>Settings Page</strong>: Control more about your experience on
          the new settings page.
        </li>
        <li>
          <strong>Abandoned Lists</strong>: When lists can't be synced due to
          external changes, automated syncs are now disabled.
        </li>
        <li>
          <strong>New Page Header</strong>: Access new pages and functions from
          the header menu when you're logged in.
        </li>
        <li>
          <strong>Animated Header Logo</strong>: Give it a hover to see it in
          action.
        </li>
        <li>
          <strong>Security Improvements</strong>: Boring technical stuff. 😴
        </li>
      </ul>

      <h2>Direct Message Notifications</h2>

      <p>
        You might have noticed that you were never prompted to share your email
        address or phone number when you signed up for Vicariously. This is 100%
        intentional – Vicariously is a Twitter first tool and having your
        contact information isn't necessary.
      </p>

      <p>
        Every blue moon or so, something changes about a list (e.g. syncs are
        failing because a list subject has switched to a protected account).
        When this happens, it'd be nice to notify the owner.
      </p>

      <p>
        Without email addresses or phone numbers, there's no traditional way of
        getting in touch with users. Fortunately, direct messages exist.
        Vicariously will now inform list owners by way of DMs when something's
        up.
      </p>

      <p>
        If you'd prefer Vicariously doesn't DM you, that's totally cool. You can
        configure your notification settings on the new settings page (see the
        next section).
      </p>

      <BlogPostImage
        src="/imgs/blog/april-2021-product-update/notification-settings.png"
        alt="Vicariously notification settings"
        width={1098}
        height={1146}
      />

      <p>
        If you're not following{" "}
        <ExternalLink className="link" href="https://twitter.com/getvicarious">
          @getvicarious
        </ExternalLink>
        , you'll see a prompt to do just that. While it's always nice to have
        more followers, the primary intent of this prompt is to ensure it's
        possible to send you DMs. Unless your account is configured to receive
        DMs from anyone, @getvicarious cannot DM you unless you follow the
        account.
      </p>

      <BlogPostImage
        src="/imgs/blog/april-2021-product-update/notification-settings-follow-prompt.png"
        alt="Vicariously notification settings with prompt to follow @getvicarious"
        width={1098}
        height={1530}
      />

      <h2>Settings Page</h2>

      <p>
        Until recently, the Vicariously app was a single page. It's a simple
        tool, so it makes sense it doesn't have a large surface area. With the
        introduction of notification settings, things were getting a little
        crowded and it was time to split out{" "}
        <Link className="link" href={settingsLinkProps().href}>
          account settings
        </Link>
        .
      </p>

      <p>
        On the new settings page, you'll find the ability to manage your
        subscription, notification settings, and privacy settings. This also
        will be the home for future feature settings.
      </p>

      <h2>Abandoned Lists</h2>

      <p>
        Now we get to the reason why the DM notification messages were added.
        The majority of lists on Vicariously are configured to be synced daily.
        Historically, Vicariously would attempt to sync every list according to
        its configuration, rain or shine.
      </p>

      <p>
        Unfortunately, some lists eventually reach a state where Vicariously
        cannot correctly sync them. This is typically due to one of the
        following reasons:
      </p>

      <ul>
        <li>
          A list subject has made their account protected and the list owner
          does not follow them
        </li>
        <li>A list subject has blocked the list owner</li>
        <li>The list owner has revoked Twitter permissions from Vicariously</li>
      </ul>

      <p>
        Rather than continuing to indefinitely attempt (and fail) to sync these
        lists, Vicariously will now "abandon" a list after it's deemed
        unredeemable. The heuristic for this may change over time, but
        currently, it's when the list has suffered from 10 consecutive failed
        syncs.
      </p>

      <BlogPostImage
        src="/imgs/blog/april-2021-product-update/abandoned-list.png"
        alt="An abandoned list"
        width={1098}
        height={402}
      />

      <p>
        When a list is abandoned, the owner will be direct messaged, letting
        them know as much. The owner can then take corrective action, unlink the
        list, or leave it as is. In the scenario where corrective action is
        taken, automated syncs can be re-enabled by initiating a forced sync.
      </p>

      <BlogPostImage
        src="/imgs/blog/april-2021-product-update/abandoned-list-details.png"
        alt="Dialog of details concerning an abandoned list"
        width={1242}
        height={930}
      />

      <h2>New Page Header</h2>

      <p>
        As previously mentioned, Vicariously used to be a single page so there
        wasn't much need for navigation in the page header. With the
        introduction of the settings page, that changes.
      </p>

      <p>Now when you're logged in, you'll notice a new header layout.</p>

      <BlogPostImage
        src="/imgs/blog/april-2021-product-update/page-header.png"
        alt="New page header layout"
        width={1242}
        height={168}
      />

      <p>
        When you click the hamburger menu, you'll be presented with a link to
        the settings page and some shortcuts to common actions.
      </p>

      <BlogPostImage
        src="/imgs/blog/april-2021-product-update/page-header-menu.png"
        alt="Page header menu"
        width={1242}
        height={930}
      />

      <h2>Animated Header Logo</h2>

      <p>
        The logo in the page header didn't previously have a hover or focus
        state. Seeing as how it's a link, it was suboptimal not to have any
        visual feedback for those states. Now when you hover over or focus on
        the logo, the film stock will rotate. It's the little things.
      </p>

      <h2>Security Improvements</h2>

      <p>
        If you made it this far, I applaud you. Unless you're interested in some
        technical details, it's probably time for you to close this tab. 👋
      </p>

      <p>
        Vicariously is built on{" "}
        <ExternalLink href="https://nextjs.org/">Next.js</ExternalLink>. I
        always assumed it set sensible security headers by default, but everyone
        knows what happens when you make an{" "}
        <ExternalLink href="https://www.youtube.com/watch?v=YS3i-irQ0L0">
          assumption
        </ExternalLink>
        ...
      </p>

      <p>
        So when I ran Vicariously through{" "}
        <ExternalLink href="https://www.webpagetest.org/">
          WebPageTest
        </ExternalLink>{" "}
        and saw that it scored pretty poorly on security, I jumped into action.
      </p>

      <p>
        Naturally, my first move was to google "nextjs security headers". I
        eventually ended up on this{" "}
        <ExternalLink href="https://github.com/vercel/next.js/issues/23993">
          Next.js GitHub issue
        </ExternalLink>{" "}
        which led me to the following tweet:
      </p>

      <EmbeddedTweet className="flex justify-center">
        <blockquote className="twitter-tweet">
          <p lang="en" dir="ltr">
            Learned about{" "}
            <a href="https://t.co/RAxyJCKWjZ">https://t.co/RAxyJCKWjZ</a> today
            🔒
            <br />
            <br />
            Here&#39;s how to take your Next.js site to an A.{" "}
            <a href="https://t.co/APq7nxngVw">pic.twitter.com/APq7nxngVw</a>
          </p>
          &mdash; Lee Robinson (@leeerob){" "}
          <a href="https://twitter.com/leeerob/status/1381605537742254082?ref_src=twsrc%5Etfw">
            April 12, 2021
          </a>
        </blockquote>
      </EmbeddedTweet>

      <p>
        After following the advice in that Twitter thread, I'm happy to announce
        Vicariously is now scoring much better in the Security category.
      </p>
    </BlogPostShell>
  );
}
