import { Card } from "client/components/Card";
import { TwitterLine } from "client/components/icons";
import { ExternalLink } from "client/components/Link";
import { LogInButton } from "client/components/LogInButton";
import { MarketingShell } from "client/components/MarketingShell";
import { format } from "date-fns";
import React from "react";
import { BlogMetadata } from "universal/types/app";

interface Props {
  meta: BlogMetadata;
  children: React.ReactNode;
}

export function BlogPostShell(props: Props) {
  const { meta, children } = props;

  return (
    <MarketingShell>
      <article>
        <div className="text-center">
          <h1 className="text-4xl font-bold">{meta.title}</h1>

          <div className="mt-2 italic sm:text-lg">
            <div>
              Written by{" "}
              <ExternalLink
                className="link"
                href="https://twitter.com/JakeHarding"
              >
                @JakeHarding
              </ExternalLink>{" "}
              on {format(meta.publishedAt, "MMMM do, yyyy")}
            </div>
          </div>
        </div>

        <div className="mt-8 prose sm:prose-lg">{children}</div>

        <div className="mt-8 sm:mt-16">
          <Card color="davys" padding="6">
            <div className="text-center font-bold sm:text-xl">
              Thanks for reading. If you haven't started using Vicariously yet,
              it's never too late!
            </div>

            <div className="mt-8 flex justify-center">
              <LogInButton size="medium" border={false} color="cultured">
                <div className="flex items-center">
                  <TwitterLine className="w-6 h-6 fill-current" />
                  <div className="ml-2">Try Vicariously</div>
                </div>
              </LogInButton>
            </div>
          </Card>
        </div>
      </article>
    </MarketingShell>
  );
}
