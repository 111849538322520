import { useField } from "react-final-form";
import { TextField as TextFieldControl } from "client/components/controls";

type Props = React.ComponentProps<typeof TextFieldControl> & {
  name: string;
  required?: boolean;
};

export function TextField(props: Props) {
  const { name, disabled: disabledProp, required } = props;

  const { input, meta } = useField(name, {
    validate: required ? validateRequired : undefined,
  });
  const disabled = meta.submitting || disabledProp;

  return <TextFieldControl disabled={disabled} {...props} {...input} />;

  function validateRequired(value: any) {
    if (value) return;

    return `${name} is required`;
  }
}
