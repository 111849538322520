import { useApi } from "client/lib/hooks/useApi";
import { SWRConfiguration } from "swr";
import { API } from "universal/types/api";
import { Maybe } from "universal/types/utils";

export function useFollowingStatus(
  userId: Maybe<string>,
  options?: SWRConfiguration<API.FollowingStatus>
) {
  const key = userId ? `/api/users/${userId}/following-status` : null;
  return useApi<API.FollowingStatus>(key, options);
}
