import { SyncFrequency } from "@prisma/client";
import { Form, SubmitButton } from "client/components/form";
import { API } from "universal/types/api";
import { Awaitable, Nullable } from "universal/types/utils";
import { ListCard } from "./ListCard";

interface Props {
  lists: API.List[];
  activeSubscription: boolean;
  onPaginate: Nullable<() => Awaitable<void>>;
  onChangeSyncFrequency: (
    listId: string,
    syncFrequency: SyncFrequency
  ) => Promise<void>;
  onUnlink: (listId: string, destroy: boolean) => Promise<void>;
  onForceSync: (listId: string) => Promise<void>;
}

export function ListManager(props: Props) {
  const {
    lists,
    activeSubscription,
    onPaginate,
    onChangeSyncFrequency,
    onUnlink,
    onForceSync,
  } = props;

  return (
    <div>
      <h1 className="text-xl font-bold sm:text-2xl md:text-4xl">Your lists</h1>

      <ul>
        {lists.map((list) => (
          <li key={list.id} className="mt-6">
            <ListCard
              activeSubscription={activeSubscription}
              list={list}
              onChangeSyncFrequency={onChangeSyncFrequency}
              onUnlink={onUnlink}
              onForceSync={onForceSync}
            />
          </li>
        ))}
      </ul>

      {renderPaginateButtonMaybe()}
    </div>
  );

  function renderPaginateButtonMaybe() {
    if (!onPaginate) return null;

    return (
      <Form className="mt-8" onSubmit={onPaginate}>
        <SubmitButton
          color="cultured"
          border={true}
          size="medium"
          fullWidth={true}
          allowPristineSubmit={true}
        >
          Load more
        </SubmitButton>
      </Form>
    );
  }
}
